// Part of earth file.

body {
  //scroll-padding-top: 100px;
}

a {
  transition: all .3s;
}

code {
  border-radius: .25rem;
  padding: .25rem;
  font-size: .8em;
  background: #f0efed;
}

.v-truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--v-truncate-lines, 5);
  overflow: hidden;
}

.article-content {
  line-height: 1.75;
  font-size: 18px;

  &.h-blog-content {
    font-size: 20px;

    img {
      border: 1px solid rgba(0, 0, 0, .075);
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 1.55em;
    margin-bottom: .8em;
    font-weight: bold;
    scroll-margin-top: 50px;
  }

  h1 {
    font-size: 2.25em;
  }

  h2 {
    font-size: 1.8em;
    color: $primary;

    &:where(h1 + *), &:where(.c-article-delimiter + *) {
      margin-top: 0;
    }
  }

  h3 {
    font-size: 1.5em;

    &:where(h2 + *), &:where(.c-article-delimiter + *) {
      margin-top: 0;
    }
  }

  h4 {
    font-size: 1.25em;
    color: $secondary;

    &:where(h3 + *), &:where(.c-article-delimiter + *) {
      margin-top: 0;
    }
  }

  h5 {
    font-size: 1em;

    &:where(h5 + *), &:where(.c-article-delimiter + *) {
      margin-top: 0;
    }
  }

  h6 {
    font-size: .875em;
  }

  p {
    margin-top: 1em;
    margin-bottom: 1em;

    //img {
    //  display: block;
    //}
  }

  li > p {
    margin-top: .5em;
    margin-bottom: .5em;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 3px solid $secondary;
    font-style: italic;
    color: #666;
    font-size: 1em;
  }

  hr {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  pre {
    margin-top: 35px;
    margin-bottom: 35px;

    code {
      border-radius: .25rem;
      background-color: $dark;
      padding: 2rem;
      font-size: .875em;
      display: block;
      color: white;
      overflow-x: scroll;
    }
  }

  figure {
    text-align: center;
  }

  .c-article-delimiter {

    width: 100%;

    &::before {
      padding-top: 1rem;
      padding-bottom: 1rem;
      content: "***";
      display: block;
      font-size: 2rem;
      line-height: 1.6em;
      letter-spacing: .5em;
      text-align: center;
    }
  }

  // Table
  .hn-table {
    display: block;
    width: 100%;
    overflow-x: auto;
    margin-top: 2em;
    margin-bottom: 2em;

    table td {
      min-width: 200px;
    }

    thead > tr > td {
      font-weight: bolder;
    }
  }

  // Image Caption
  figure {
    footer {
      margin-top: .5rem;
      color: var(--bs-gray-600);
      font-size: .875em;
    }

    footer a {
      color: var(--bs-gray-700);
      text-decoration: underline;
    }
  }

  img.image--center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  // Code
}
