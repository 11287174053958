// Part of simular file.

.view-share-list {
  .c-banner {
    border-bottom-right-radius: 400px;
  }

  .c-share-item {
    &__image {
      //padding-top: 42.857%;
      //background-size: cover;
      //background-position: center center;
      //background-repeat: no-repeat;
    }
  }
}

.l-share-sidebar {
  top: 50px;
  //height: calc(100vh - 50px);

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    width: 5px;
    background: rgba(0, 0, 0, 0);
    transition: all .3s;
  }

  &:hover::-webkit-scrollbar-thumb {
    width: 5px;
    background: $primary;
  }
}
