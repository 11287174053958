
.c-main-navbar {
  background: transparent;
  margin-top: 30px;
  transition: all .5s;

  &.h-hide {
    margin-top: -100px;
  }

  .navbar-brand {
    margin-bottom: 0;
    padding: 0;
  }

  .navbar-nav .nav-link {
    padding-left: 1.15rem;
    padding-right: 1.15rem;
  }

  &__menu {

    a:not(.active), button:not(:active), .nav-link:not(.active) {
      color: $dark !important;

      @include hover-focus() {
        color: rgba($dark, .75) !important;
      }
    }
  }
}

.c-mainmenu {
  .dropdown-menu {
    border: none;
    box-shadow: $box-shadow;
    border-radius: 1rem;
    padding: 1.2rem 1rem 1.5rem;
    margin-top: 1rem;
  }

  .dropdown-fade .dropdown > .dropdown-menu {
    transition-delay: .5s, .5s, .25s;
  }

  .dropdown-fade .dropdown:hover > .dropdown-menu {
    transition-delay: 0s, 0s, 0s;
  }

  .c-mega-subitem {
    li {
      width: 100%;
    }
  }

  .dropdown-item {
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-radius: .5rem;
  }
}

.c-mobile-menu-toggler {
  margin-right: -1rem;

  @include hover-focus() {
    box-shadow: none;
  }

  &__icon {
    transform: scale(1.5);
    width: 20px;
  }
}
