// Part of simular file.

.c-contact-info {
  cursor: pointer;

  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 220px;
  }
}

.l-contact-info__address {
  .c-contact-info__content {
    > div {
      height: 1.5rem;
      transition: height .3s;
      max-width: 150px;
    }
  }

  &:hover {
    .c-contact-info__content {
      > div {
        height: 3rem;
        white-space: normal !important;
        word-wrap: break-word !important;
      }
    }
  }
}
