// Move down content because we have a fixed navbar that is 50px tall
html {
  overflow-x: hidden;
}

body {
  //padding-top: 50px;
  //padding-bottom: 20px;
  background-color: $gray-100;
}

.navbar-brand img {

}

.l-wrapper {
  overflow-x: hidden;

  &::-webkit-scrollbar {
    //width: 0;
  }
}

.h-no-transitions * {
  transition: none !important;
}

#bottom-bar {
  gap: 8px;
}
