.c-finance-banner {
  position: relative;
  height: 864px;
  background: #FF6666;
  overflow: hidden;
  transition: 1s;

  @media (min-width: 1600px) {
    height: 949px;
  }

  @media (max-width: 992px) {
    height: 1221px;
  }

  @media (max-width: 768px) {
    height: 1000px;
  }

  @media (max-width: 400px) {
    height: 912px;
  }

  &__row {
    padding-top: 160px;

    @media (max-width: 1200px) {
      align-items: center;
    }

    @media (max-width: 992px) {
      padding-top: 144px;
      position: relative;
      z-index: 5;
    }
  }

  &__content {
    padding-top: 32px;

    @media (max-width: 992px) {
      padding-top: 0;
    }

    .banner-subtitle {
      font-size: 1.25rem;
      font-weight: 500;
    }

    .banner-title {
      font-size: 64px;
      font-weight: 300;

      @media (max-width: 992px) {
        font-size: 48px;
      }
    }

    .banner-info {
      margin-top: 20px;
      line-height: 32px;
    }
  }

  .bg-banner-text {
    position: absolute;
    z-index: 0;
    top: 150px;
    left: 0;
    width: 644px;
    height: auto;
  }

  .c-chatbox {
    text-align: center;
    width: fit-content;
    position: absolute;
    right: 396px;
    top: -29px;
    z-index: 11;

    @media (min-width: 1600px) {
      right: 303px;
      top: -41px;
    }

    @media (min-width: 1700px) {
      right: 420px;
      top: -10px;
    }

    &__content {
      position: relative;
      padding: 6px 12px 6px 14px;
      border-radius: 1rem;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 12px;

      &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 100%);
      }
    }

    &__circle {
      position: relative;
      margin: 0 auto;
      width: 26px;
      height: 26px;
      border-radius: 50%;

      &:before {
        content: "";
        width: 12px;
        height: 12px;
        border: 1px solid #ffffff;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .chatbox1 {

    .c-chatbox__content {
      background-color: #2577BE;
      color: white;

      &:before {
        border-top: 10px solid #2577BE;
      }
    }

    .c-chatbox__circle {
      background-color: rgba(36, 119, 191, 0.3);

      &:before {
        background-color: #2577BE;
      }
    }
  }

  .chatbox2 {

    .c-chatbox__content {
      background-color: #FFD940;
      color: #474747;

      &:before {
        border-top: 10px solid #FFD940;
      }
    }

    .c-chatbox__circle {
      background-color: rgba(250, 209, 60, 0.3);

      &:before {
        background-color: #FBD03B;
      }
    }
  }

  .chatbox3 {

    .c-chatbox__content {
      background-color: #FD9800;
      color: #F9F9F9;

      &:before {
        border-top: 10px solid #FD9800;
      }
    }

    .c-chatbox__circle {
      background-color: rgba(252, 151, 0, 0.3);

      &:before {
        background-color: #FD9800;
      }
    }
  }

  .chatbox4 {

    .c-chatbox__content {
      background-color: #204272;
      color: white;

      &:before {
        border-top: 10px solid #204272;
      }
    }

    .c-chatbox__circle {
      background-color: rgba(32, 67, 115, 0.3);

      &:before {
        background-color: #204272;
      }
    }
  }

  .chatbox5 {

    .c-chatbox__content {
      background-color: #F28A8A;
      color: white;

      &:before {
        border-top: 10px solid #F28A8A;
      }
    }

    .c-chatbox__circle {
      background-color: rgba(250, 209, 60, 0.3);

      &:before {
        background-color: #FBD03B;
      }
    }
  }

  .bg-banner-wave {
    position: absolute;
    left: -17%;
    bottom: 0;
    transition: 1s;
    z-index: 1;

    @media (min-width: 1600px) {
      left: -5%;
      bottom: 97px;
    }

    @media (min-width: 1700px) {
      left: 0;
    }

    @media (max-width: 992px) {
      bottom: -117px;
    }

    @media (max-width: 400px) {
      left: -86%;
    }

    img {
      height: 387px;

      @media (min-width: 1700px) {
        width: 110%;
        height: auto;
      }

      @media (min-width: 1600px) {
        width: 100%;
        height: auto;
      }
    }
  }

  .banner-bottom {
    width: 101%;
    height: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3;
  }

  .bg-arrow {
    width: 64px;
    height: auto;
    position: absolute;
    right: 0;
    bottom: 96px;
    z-index: 3;

    @media (max-width: 992px) {
      right: 8%;
      bottom: 5px;
    }
  }

  .l-browser-swiper {
    position: absolute;
    width: 100%;
    top: 17%;

    @media (max-width: 992px) {
      width: 90%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .banner-swiper1,
  .banner-swiper2 {
    width: 100%;
    height: 100%;

    .swiper-slide {
      width: 100%;
      height: fit-content;
      border-radius: 1rem;

      img {
        width: 100%;
        height: auto;
        border-radius: 1rem;
      }
    }
  }

  .l-left-bottom-swiper {
    width: fit-content;
    position: absolute;
    left: 12px;
    bottom: -65px;
    z-index: 5;

    @media (min-width: 400px) {
      left: 28px;
      bottom: -65px;
    }

    @media (min-width: 576px) {
      left: 20px;
      bottom: -95px;
    }

    @media (min-width: 768px) {
      left: 42px;
      bottom: -96px;
    }

    @media (min-width: 992px) {
      left: -20px;
      bottom: -128px;
    }

    @media (min-width: 1200px) {
      left: -13px;
      bottom: -117px;
    }

    @media (min-width: 1600px) {
      left: -30px;
      bottom: -111px;
    }

    .banner-swiper3 {
      width: 210px;
      height: 155px;

      @media (max-width: 576px) {
        width: 151px;
        height: 111.5px;
      }

      .swiper-slide {
        width: 100%;
        height: fit-content;
        border-radius: 1rem;

        img {
          width: 100%;
          height: auto;
          border-radius: 1rem;
        }
      }
    }
  }

  .l-bottom-swiper {
    width: fit-content;
    position: absolute;
    left: 136px;
    bottom: -91px;
    z-index: 5;

    @media (min-width: 400px) {
      left: 155px;
      bottom: -91px;
    }

    @media (min-width: 576px) {
      left: 187px;
      bottom: -162px;
    }

    @media (min-width: 768px) {
      left: 214px;
      bottom: -150px;
    }

    @media (min-width: 992px) {
      left: 151px;
      bottom: -200px;
    }

    @media (min-width: 1200px) {
      left: 157px;
      bottom: -196px;
    }

    @media (min-width: 1600px) {
      left: 128px;
      bottom: -190px;
    }

    .banner-swiper4 {
      width: 131px;
      height: 133px;

      @media (max-width: 576px) {
        width: 75px;
        height: 76px;
      }

      .swiper-slide {
        width: 100%;
        height: fit-content;
        border-radius: 1rem;

        img {
          width: 100%;
          height: auto;
          border-radius: 1rem;
        }
      }
    }
  }

  //  banner 背景線條
  .bg-banner-line--left {
    position: absolute;
    width: 62.7px;
    left: 14px;
    top: 0;
    z-index: 9;
  }

  .bg-banner-line--bottom {
    position: absolute;
    width: 44px;
    left: 54px;
    top: 460px;
    z-index: 0;

    @media (max-width: 992px) {
      display: none;
    }
  }

  .bg-banner-line--right {
    position: absolute;
    width: 12px;
    right: 54px;
    top: 466px;
    z-index: 9;

    @media (min-width: 1440px) {
      right: 10%;
    }

    @media (max-width: 576px) {
      right: 25px;
      top: 790px;
    }
  }

  .bg-banner-line--right1 {
    position: absolute;
    width: 29px;
    right: 16px;
    top: 441px;
    z-index: 0;

    @media (min-width: 1440px) {
      right: 7%
    }

    @media (max-width: 992px) {
      display: none;
    }
  }
}

// Swiper
.swiper {
  visibility: hidden;

  &.swiper-initialized {
    visibility: visible;
  }
}

// Page Content
.layout-solutions-finance-marketing {

  .l-finance-page {

    .l-section {

      &--feature {
        position: relative;
        padding-top: 140px;
        padding-bottom: 140px;
        margin-top: -240px;
        z-index: 1;

        @media (min-width: 1600px) {
          margin-top: -300px;
        }

        @media (max-width: 992px) {
          padding-bottom: 48px;
        }

        @media (max-width: 768px) {
          margin-top: -170px;
        }

        .img-feature {
          width: 130px;
          height: auto;
        }

        .feature-title {
          color: #4D4D4D;
        }

        .feature-subtitle {
          font-size: 14px;
          color: #999999;
          line-height: 24px;
        }

        .bg-feature {
          position: absolute;
          left: 50px;
          top: 250px;
          z-index: 9;

          @media (max-width: 1440px) {
            top: 210px;
          }

          @media (max-width: 768px) {
            display: none;
          }
        }

        .bg-service2 {
          max-width: 73px;
          position: absolute;
          top: 846px;
          right: 10%;
          z-index: 9;

          @media (min-width: 992px) {
            top: 579px;
          }

          @media (min-width: 1200px) {
            top: 790px;
          }

          @media (min-width: 1440px) {
            top: 579px;
          }
        }
      }

      &--service {
        position: relative;
        overflow: hidden;
        padding-bottom: 120px;

        @media (max-width: 992px) {
          padding-top: 48px;
          padding-bottom: 48px;
          background-color: white;
        }

        &__row {
          margin-top: 1rem;

          @media (min-width: 992px) {
            margin-top: 66px;
          }
        }

        .bg-service {
          width: 100%;
          height: auto;
          position: relative;
          left: 0;
          top: 0;
          z-index: 0;
          transform: translateX(-3%);

          @media (min-width: 576px) {
            width: 130%;
            transform: translateX(-19%);
          }

          @media (min-width: 768px) {
            width: 120%;
            transform: translateX(-18%);
          }
        }

        .img-service1 {
          width: 100%;
          height: auto;
          position: relative;
          left: 0;
          top: 0;
          transform: translateX(-3%);
          z-index: 1;

          @media (min-width: 992px) {
            position: absolute;
            width: 132%;
            transform: translateX(-28%);
          }

          @media (min-width: 1200px) {
            width: 120%;
            transform: translateX(-25%);
          }

          @media (min-width: 1400px) {
            width: 100%;
            transform: translateX(-7%);
          }
        }

        .img-service2 {
          width: 100%;
          height: auto;
          position: relative;
          left: 0;
          top: 0;
          z-index: 0;
          transform: translateX(-3%);

          @media (min-width: 992px) {
            position: absolute;
            top: 127px;
            width: 160%;
            transform: translateX(-39%);
          }

          @media (min-width: 1200px) {
            top: 127px;
            width: 181%;
            transform: translateX(-49%);
          }

          @media (min-width: 1400px) {
            width: 160%;
            transform: translateX(-41%);
          }

          @media (min-width: 1600px) {
            width: 141%;
            transform: translateX(-32%);
          }
        }

        .bg-service-top {
          width: 15px;
          position: absolute;
          left: -5%;
          top: 207px;
          z-index: -1;

          @media (max-width: 1440px) {
            top: 287px;
          }

          img {
            width: 100%;
            height: auto;
          }
        }

        .bg-service-right {
          width: 40px;
          position: absolute;
          right: 19%;
          top: 650px;

          @media (max-width: 1440px) {
            top: 600px;
          }

          img {
            width: 100%;
            height: auto;
          }
        }

        .bg-service-bottom {
          width: 15px;
          position: absolute;
          left: 6%;
          top: 936px;

          @media (max-width: 1440px) {
            top: 1000px;
          }

          img {
            width: 100%;
            height: auto;
          }
        }
      }

      &--creative {
        position: relative;
        padding-top: 48px;
        background: $primary;


        @media (min-width: 992px) {
          border-top-left-radius: 224px;
          padding: 88px 0;
          background: $primary url("../../images/pages/finance/img-sprint.png") no-repeat left bottom;
          background-size: 60%;
        }

        @media (min-width: 1200px) {
          padding: 120px 0;
          background-size: 57%;
        }

        @media (min-width: 1500px) {
          padding: 120px 0;
          background-size: 53%;
        }

        .sprint-info {
          color: rgba(255, 255, 255, 0.8);
          letter-spacing: 0.01rem;
          line-height: 32px;
        }

        .bg-sprint-left {
          max-width: 52px;
          position: absolute;
          left: 5%;
          top: 311px;
          z-index: 9;

          @media (max-width: 1440px) {
            top: 389px;
          }

          @media (max-width: 992px) {
            display: none;
          }
        }

        .bg-sprint-right {
          max-width: 62px;
          position: absolute;
          right: 9%;
          top: 490px;
          z-index: 9;

          @media (max-width: 1440px) {
            top: 580px;
          }

          @media (max-width: 992px) {
            display: none;
          }
        }

        .bg-sprint-bottom {
          max-width: 23px;
          position: absolute;
          right: 29%;
          top: 1000px;
          z-index: 9;

          @media (max-width: 1440px) {
            top: 1100px;
          }

          @media (max-width: 992px) {
            display: none;
          }
        }
      }

      &--interactive {
        position: relative;
        padding: 96px 0;
        max-width: 100%;
        overflow-x: hidden;
        background: #EBEBEB url("../../images/pages/finance/bg-case.png") no-repeat center bottom;

        @media (max-width: 992px) {
          padding: 48px 0;
        }

        .rwd-video--interactive {
          padding-top: 13px;
          z-index: 3;

          @media (min-width: 1400px) {
            padding-bottom: 40.25%;
          }

          video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
            border: 0;
            z-index: 3;
            padding: 4.9% 12.5% 8.8%;

            @media (min-width: 1400px) {
              padding: 32px 21.5% 0 21.5%;
            }

            @media (min-width: 1200px) and (max-width: 1400px) {
              padding: 24px 9% 0 9%;
            }

            @media(min-width: 992px) and (max-width: 1200px) {
              padding: 17px 8.5% 0 8.5%;
            }

            @media(max-width: 992px) {
              padding: 24px 9.5% 0 9.5%;
            }

            @media(max-width: 768px) {
              padding: 18px 8.5% 0 8.5%;
            }

            @media(max-width: 460px) {
              padding: 4% 7.9% 0 8%;
            }
          }
        }

        .bg-laptop {
          position: absolute;
          width: 100%;
          left: 0;
          top: 0;
          z-index: 3;

          img {

            @media (min-width: 1400px) {
              width: 71%;
            }
          }
        }

        .bg-case1 {
          display: none;

          @media (min-width: 1024px) {
            display: block;
            width: 100%;
            max-width: 405px;
            position: absolute;
            top: 20%;
            left: 72%;
            z-index: 0;
          }

          @media (min-width: 1100px) {
            left: 76%;
            z-index: 0;
          }

          @media (min-width: 1200px) {
            left: 79%;
            z-index: 0;
          }

          @media (min-width: 1400px) {
            left: 74%;
            z-index: 5;
          }
        }

        .bg-case2 {
          display: none;

          @media (min-width: 1024px) {
            display: block;
            width: 100%;
            max-width: 347px;
            position: absolute;
            top: -57px;
            left: 83%;
            z-index: 0;
          }

          @media (min-width: 1100px) {
            top: -57px;
            left: 86%;
          }

          @media (min-width: 1400px) {
            left: 79%;
          }
        }

        .bg-case3 {
          display: none;

          @media (min-width: 1024px) {
            display: block;
            width: 100%;
            max-width: 327px;
            position: absolute;
            top: -19px;
            left: -45%;
            z-index: 5;
          }

          @media (min-width: 1200px) {
            max-width: 386px;
            top: -19px;
            left: -37%;
          }

          @media (min-width: 1400px) {
            top: -19px;
            left: 0;
          }
        }

        .bg-case4 {
          display: none;

          @media (min-width: 1024px) {
            display: block;
            width: 100%;
            max-width: 234px;
            position: absolute;
            top: 48%;
            left: -36%;
            z-index: 5;
          }

          @media (min-width: 1200px) {
            max-width: 240px;
            top: 48%;
            left: -24%;
          }

          @media (min-width: 1400px) {
            max-width: 240px;
            top: 47%;
            left: 58px;
          }
        }
      }

      &--chart {
        position: relative;
        padding-top: 90px;
        padding-bottom: 100px;
        background: white url("../../images/pages/finance/bg-chart.png") no-repeat;
        background-size: cover;

        @media (max-width: 992px) {
          padding-top: 48px;
          padding-bottom: 48px;
          background: #FFC701;
        }

        .bg-chart2 {
          max-width: 64px;
          position: absolute;
          right: 15%;
          top: 595px;
          z-index: 1;

          @media (max-width: 1440px) {
            top: 595px;
          }

          @media (max-width: 1280px) {
            top: 780px;
          }

          @media (max-width: 992px) {
            display: none;
          }
        }

        .chart-title-wrap {

          @media (min-width: 992px) {
            padding-top: 70px;
          }
        }
      }

      &--flow {
        padding-top: 98px;
        padding-bottom: 120px;
        background-color: white;

        @media (max-width: 992px) {
          padding-top: 48px;
          padding-bottom: 48px;
        }

        .flow-swiper {
          width: 100%;
          height: auto;
          padding-bottom: 15px;

          .swiper-slide {
            width: 100%;
            height: auto;
            border-radius: 1rem;
            box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .1);

            @media (max-width: 576px) {
              border-radius: 2rem;
            }

            img {
              width: 100%;
              height: auto;
              border-radius: 1rem;

              @media (max-width: 576px) {
                border-radius: 2rem;
              }
            }
          }
        }

        .swiper-pagination {
          bottom: -55px;
        }

        .swiper-pagination-bullet {
          background-color: #D8D8D8;
          width: 20px;
          height: 5px;
          border-radius: 20px;
          opacity: 1;
        }

        .swiper-pagination-bullet-active {
          width: 50px;
          background-color: $primary;
        }

        .swiper-button-prev {
          left: 60px;
          margin-top: 0;
          padding: 3rem 10px;
          background-color: rgba(0, 0, 0, 0.5);
          border-radius: 32px;
          transform: translateY(-50%);
          opacity: 0;
          transition: .15s ease-in-out;

          @media (max-width: 450px) {
            padding: 2.25rem 10px;
          }
        }

        .swiper-button-prev::after {
          font: var(--fa-font-solid);
          content: "\f060";
          color: white;
          font-size: 1.5rem;
        }

        .swiper-button-next {
          right: 60px;
          margin-top: 0;
          padding: 3rem 10px;
          background-color: rgba(0, 0, 0, 0.5);
          border-radius: 32px;
          transform: translateY(-50%);
          opacity: 0;
          transition: .15s ease-in-out;

          @media (max-width: 450px) {
            padding: 2.25rem 10px;
          }
        }

        .swiper-button-next::after {
          font: var(--fa-font-solid);
          content: "\f061";
          color: white;
          font-size: 1.5rem;
        }

        .swiper-nav-show {
          opacity: 1;
        }

        .bg-step {
          max-width: 57px;
          position: absolute;
          top: 897px;
          left: 25%;
          z-index: 1;

          @media (max-width: 1440px) {
            top: 1100px;
          }

          @media (max-width: 992px) {
            display: none;
          }
        }
      }

      &--story {
        padding-top: 160px;
        height: 800px;
        background: white url("../../images/pages/finance/bg-story.png") no-repeat center;

        @media (max-width: 992px) {
          padding-top: 48px;
          height: 700px;
          background: #FF6666 url("../../images/pages/finance/bg-story.png") no-repeat center bottom;
          background-size: 150%;
        }

        @media (max-width: 768px) {
          background-size: 189%;
        }

        @media (max-width: 576px) {
          background-size: 189%;
        }

        @media (max-width: 400px) {
          background-size: 233%;
        }

        .story-info {
          text-align: left;
          color: rgba(255, 255, 255, 0.8);
          line-height: 38px;
          width: 80%;
          margin: 8px auto 0;

          @media (max-width: 992px) {
            width: 100%;
            margin: 8px 0 0 0;
          }
        }
      }

      &--industry {
        position: relative;
        background-color: white;
        padding-top: 82px;
        padding-bottom: 132px;

        @media (max-width: 992px) {
          overflow: hidden;
          padding-bottom: 90px;
        }

        .industry-type {
          font-size: 24px;
          color: $gray-800;
          margin-top: 24px;
        }

        .bg-industry1 {
          max-width: 62.7px;
          position: absolute;
          top: 1270px;
          right: 24%;

          @media (max-width: 1440px) {
            top: 1405px;
          }

          @media (max-width: 992px) {
            display: none;
          }
        }

        .bg-industry2 {
          max-width: 65px;
          position: absolute;
          top: 1390px;
          left: 8%;
          z-index: 9;

          @media (max-width: 1440px) {
            top: 1640px;
          }

          @media (max-width: 992px) {
            left: 5%;
            top: -685px;
          }

          @media (max-width: 576px) {
            left: 5%;
            top: 2340px;
          }
        }
      }
    }
  }

  .chart-card {
    border-radius: 1rem;
    border: none;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
    padding: 2rem 2rem;

    @media (min-width: 992px) {
      padding: 24px 40px;
    }
  }

  .form-group {
    &-text {
      color: $warning;
      font-size: 14px;
    }

    button {
      font-size: 18px;
      border-width: 2px;
    }

    .custom-select {
      color: $warning;
      font-weight: 500;
      border: 2px solid $warning;
      border-radius: 10px;
      display: inline-block;
      width: 100%;
      padding: 0.375rem 1.75rem 0.375rem 0.75rem;
      font-size: 18px;
      vertical-align: middle;
      background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat #fff;
      border-radius: 10px;
      appearance: none;

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }

  .c-chart-cover {
    background-color: #ffffff;
    width: 100px;
    height: 16px;
    position: absolute;
    left: 10px;
    bottom: 81px;

    @media (min-width: 576px) {
      bottom: 79px;
    }

    @media (min-width: 992px) {
      bottom: 70px;
    }
  }

  .amcharts-range-selector-range-wrapper,
  .amcharts-range-selector-title {
    display: none;
  }

  .amcharts-range-selector-period-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .amcharts-range-selector-period-button {
    margin: 0 20px 0 0;
    padding: 0.5rem 1rem;
    background-color: white;
    color: $warning;
    border: 2px solid $warning;
    border-radius: 10px;

    @media (max-width: 576px) {
      margin: 0 1rem 0 0;
    }

    &:hover {
      background-color: $warning;
      color: white;
    }

    &.active {
      background-color: $warning;
      color: white;
    }

    &:last-child {
      margin: 0;
    }
  }

  // footer for this page only
  .l-footer-wrapper--cta {
    background: #EBEBEB;
  }
}
