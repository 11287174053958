// Part of simular file.

.btn {
  transition: all .35s;

  &.shadow {
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.07) !important;

    &:hover {
      box-shadow: 0 7px 10px rgba(0, 0, 0, 0.14) !important;
    }
  }
}

.btn-xs {
  @include button-size(.2rem, .875rem, .75rem, 50px);
}

.btn-primary {
  &:hover {
    background-color: #E65C5C;
    border-color: #E65C5C;
  }
}

.btn-white {
  @include button-variant(#fcfcfc, #fcfcfc, $white, darken($white, 5%), $gray-200, $gray-200);
  color: $primary;

  &:hover, &:focus {
    color: darken($primary, 5%);
  }
}

.btn-outline-white {
  @include button-outline-variant(white, white, $white, $white);
  color: white;

  &:hover {
    color: darken($primary, 5%);
  }
}

.btn-secondary, .btn-outline-secondary {
  &:hover {
    background-color: #FCFCFC;
    border-color: #FCFCFC;
  }
}
