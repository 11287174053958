// Part of simular file.

.shadow-hover {
  transition: box-shadow .5s;

  &.shadow-sm:hover {
    transition: box-shadow .3s;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05), 0 1px 1px 0 rgba(0, 0, 0, 0.1) !important;
  }
}
