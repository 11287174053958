
.l-footer-wrapper {
  overflow: hidden;

  &--simple {
    .l-footer {
      //margin-top: -230px;
      //padding-top: 200px;
      margin-top: 10rem;
      border-top-left-radius: 385px;
    }
  }

  &--cta {
    //margin-top: -300px;

    .l-footer {
      margin-top: -230px;
      padding-top: 200px;
    }
  }
}

.l-footer {
  --top-rounded: 50px;

  position: relative;
  overflow: hidden;
  border-top-left-radius: var(--top-rounded);
  border-top-right-radius: var(--top-rounded);

  &__logo {
    margin-top: -50px;
  }

  &__overlay {
    top: -555px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;

    img {
      opacity: .35;
    }
  }

  &__copyright {
    font-size: .625rem;
    font-weight: 300;
    letter-spacing: 1px;
  }
}

.c-footer-menu {
  color: $gray-800 !important;

  .nav-link {
    padding-left: 0;
    color: $gray-800 !important;

    @include hover-focus() {
      color: $primary !important;
    }

    &.active {
      color: $primary !important;
    }
  }
}

// Contact CTA
.l-contact {
  position: relative;
  margin-top: 200px;

  &__shape {
    position: absolute;
    top: -0px;
    right: -450px;
    transform: scale(1.5) rotateZ(17deg);
    width: 100%;

    > div {
      height: 600px;
      background: url(../../images/pages/home/float-shape-2.svg) no-repeat center center;
      transition: transform .1s;
    }
  }

  &__mount {
    position: absolute;
    top: -350px;
    width: 100%;

    > div {
      background: url(../../images/pages/home/bg-mount.webp) no-repeat center center;
      height: 800px;
      transition: transform .1s;
    }

    @include media-breakpoint-down(lg) {
      top: -300px;
      background-size: 140%;
    }
  }

  &__body {
    position: relative;
    z-index: 1;
    height: 600px;
    color: white;
    background: url(../../images/pages/home/red-shape.svg) no-repeat center center;
  }

  @include media-breakpoint-down(md) {
    margin-top: 200px;

    &__shape {
      top: -200px;
      right: -100px;
      height: 400px;

      > div {
        background-size: contain;
      }
    }

    &__mount {
      top: -450px;

      > div {
        background-size: contain;
      }
    }
  }
}
