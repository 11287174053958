/**
 * Part of earth project.
 *
 * @copyright  Copyright (C) 2018 {ORGANIZATION}. All rights reserved.
 * @license    GNU General Public License version 2 or later;
 */

// Variables
@import "variables";

// Base
@import "base/polyfill";
@import "base/typography";
@import "base/utilities";
@import "base/extended";

// component
@import "components/breadcrumb";
@import "components/buttons";
@import "components/card";
@import "components/slider";
@import "components/shadow";
@import "components/form";
@import "components/image";

// Layout
@import "layout/base";
@import "layout/header";
@import "layout/menu";
@import "layout/banner";
@import "layout/footer";
@import "layout/promo-sections";

// Pages
@import "pages/partners";
@import "pages/contact";
@import "pages/resources";
@import "pages/blog";
@import "pages/finance";

// SCSS file for ArticleItem

.view-article-item {
    //
}

// SCSS file for ArticleList

.view-article-list {
    //
}

// SCSS file for Auth

// SCSS file for ForgetComplete

.view-forget-complete {
    //
}

// SCSS file for ForgetRequest

.view-forget-request {
    //
}

// SCSS file for ForgetReset

.view-forget-reset {
    //
}

// SCSS file for Error

.view-error {
    //
}

// SCSS file for profile-edit

.view-profile-edit {
    //
}

// SCSS file for Registration

// SCSS file for PortfolioItem

.view-portfolio-item {
    //
}

// SCSS file for PortfolioList

.view-portfolio-list {
    //
}

// SCSS file for MemberItem

.view-member-item {
    //
}

// SCSS file for MemberList

.view-member-list {
    //
}

// SCSS file for contact-edit

.view-contact {
    //
}

// SCSS file for ArticleItem

.view-article-item {

}

// SCSS file for ArticleList

.view-article-list {
    //
}

// SCSS file for contact-edit

.view-contact {
    #input-item-budget-wrapper {
      .card, label {
        cursor: pointer;
        transition: .3s all;
      }

      .card.active .card-body {
        transition: all .3s;
        box-shadow: 0 0 0 2px $primary;
        border-radius: 1rem;
      }

      input[type=radio].is-valid {
        border-color: $secondary;
      }

      input[type=radio]:checked {
        background-color: $primary !important;
      }
      input[type=radio]:focus {
        box-shadow: none;
        border: $primary;
      }
    }
}

// Home SCSS

.view-home {
  .l-main {
    overflow-x: hidden;
    padding-top: 100px;
    margin-top: -100px;
  }

  .c-banner {
    border-bottom-right-radius: 600px;
    overflow: hidden;

    &__overlay {
      left: 50%;
      transform: translateX(-46%);

      bottom: -1rem;
    }

    &__content {
      @include media-breakpoint-down(md) {
        .display-1 {
          font-size: 3.75rem;
        }

        .h3 {
          font-size: 1.5rem;
        }
      }
    }
  }

  // Features
  .l-section--features {
    position: relative;

    .c-float-shape {
      position: absolute;
      right: -30px;
      top: -75px;
    }

    .c-feature-item {
      transition: all .35s;

      svg g > g {
        transition: all .35s;
      }

      &:hover {
        color: $primary;

        svg g > g {
          stroke: $primary;
        }
      }

      &__text {
        font-size: 1.125rem;
      }
    }
  }

  // About
  .l-section--about {
    min-height: 250px;

    @include media-breakpoint-up(lg) {
      min-height: 500px;
    }

    .l-section__overlay {
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      .c-float-line {
        transition: transform .3s;
      }

      .c-float-line svg {
        @include media-breakpoint-down(lg) {
          height: 300px;
        }
      }
    }
  }

  // Works
  .l-works {
    .owl-dots {
      margin-top: 1.5rem;
      text-align: center;

      .owl-dot {
        width: 20px;
        height: 5px;
        margin-left: 4px;
        margin-right: 4px;
        border-radius: 30px;
        background-color: $gray-400;
        transition: all .3s;

        &.active {
          background-color: $primary !important;
          width: 50px;
        }

        @include hover-focus {
          outline: none;
          background-color: darken($gray-400, 10%);
        }
      }
    }

    .c-slider-nav {
      width: 100%;
      max-width: 650px;
      margin-left: auto;
      margin-right: auto;
      position: relative;

      .c-button span {
        font-size: 2rem;
        text-shadow: 0 2px 5px rgba(0, 0, 0, .5);
      }

      .c-button {
        top: auto;
        bottom: -20px;
        transform: none;
        height: 50px;
        width: 7rem;
        border-radius: 0 !important;
        border: none;
        opacity: .7;
        transition: all .3s;

        &:hover {
          opacity: 1;
        }
      }

      .c-button--previous {

      }

      .c-button--next {

      }
    }
  }

  .c-works-slider .owl-stage {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .c-work-item {
    &__image {
      //padding-top: 46%;
      //width: 100%;
      //background-position: center center;
      //background-size: cover;
    }

    &__description {
      opacity: 0;
      transition: opacity .35s;
    }

    &__text {
      line-height: 2;
    }
  }

  .owl-item.center {
    .c-work-item__description {
      opacity: 1;
    }
  }

  // Serves
  .l-serves {
    position: relative;
    margin-top: 250px;

    @include media-breakpoint-down(md) {
      margin-top: 0;
    }

    .c-float-shape {
      position: absolute;
      left: 43%;
      transform: translateX(-50%);
      top: -110px;
    }

    .c-screen-img {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 53%;
    }

    @include media-breakpoint-down(md) {
      .c-screen-img {
        position: relative;
        transform: none;
        width: 90%;
      }

      .c-float-shape {
        top: 50vw;
        left: auto;
        right: -50px;
        transform: none;
      }
    }
  }

  .c-serve-item {
    text-align: center;
    transition: all .3s;
    min-width: 100px;
    margin-bottom: 3rem;
    flex: 0 0 (100% / 12 * 4);

    @include media-breakpoint-down(sm) {
      flex: 0 0 (100% / 12 * 6);
    }

    svg > g path {
      transition: all .3s;
    }

    &:hover {
      color: $primary !important;
      text-decoration: none;

      svg > g path {
        stroke: $primary;
      }
    }
  }

  // Core
  .l-core {
    margin-top: 200px;
    margin-bottom: 100px;

    .c-cat-img {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(5%, -50%);
      width: 65%;
    }

    &__text {
      line-height: 2.3;
      position: relative;
      z-index: 1;
    }

    @include media-breakpoint-down(md) {
      margin-top: 0;

      .c-cat-img {
        position: relative;
        top: 0;
        left: -40%;
        transform: none;
        width: 200%;
        max-width: 1200px;
      }
    }
  }

  // Solutions
  .c-solution-item {
    //max-width: 300px;
  }
}

.view-opensource-list {
  --body-round: 150px;

  .c-banner--opensource {
    @include media-breakpoint-up(lg) {
      border-bottom-left-radius: var(--body-round);
      border-bottom-right-radius: var(--body-round);
    }

    padding-bottom: 50px;
  }

  .l-opensource-page {
    //background-color: #f9f9f9;
    //padding-top: 100px;
    //padding-bottom: 150px;
    //
    //margin-top: -180px;
    //
    //border-top-left-radius: var(--body-round);
    //border-top-right-radius: var(--body-round);
    position: relative;
    z-index: 1;

    padding-top: 64px;
  }

  .l-os-section {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .c-os-item {
    transition: all .3s;
    opacity: .9;

    &:hover {
      opacity: 1;
    }

    &__cover img {
      border: 1px solid rgba(0, 0, 0, .15);
    }
  }
}

// Part of simular file.

.layout-services-serve {
  .c-serve-item {
    text-align: center;
    transition: all .3s;

    svg {
      width: 117px;
      height: 117px;

      > g path {
        transition: all .3s;
      }
    }

    &:hover, &.active {
      color: $primary !important;

      svg > g path {
        stroke: $primary !important;
      }
    }

    @include media-breakpoint-down(sm) {
      svg {
        width: 64px;
        height: 64px;
      }

      width: 25%;

      font-size: .75rem;
    }
  }

  .c-service-slider-item {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    &__content {
      position: relative;
      z-index: 1;
    }

    &__body {
      height: 400px;
    }

    .h-bg-overlay {
      background-color: $primary;
      opacity: .8;
    }
  }

  // Timeline
  // ----------------------------------------------------
  .l-working-flow {
    position: relative;
    display: flex;
    height: 2300px;

    @include media-breakpoint-down(md) {
      height: 2500px;
    }

    @include media-breakpoint-down(xs) {
      height: 2200px;
    }

    //&__container {
    //  position: absolute;
    //  left: 50%;
    //  transform: translateX(-50%);
    //  width: 1140px;
    //  padding-left: 15px;
    //  padding-right: 15px;
    //}
  }

  .c-timeline {
    position: relative;
    height: 1800px;

    .c-timeline-step {
      display: flex;
      justify-content: space-between;
      width: 380px;
      right: 280px;
      position: absolute;

      &__card {
        position: sticky;
        top: 100px;
      }

      &--right {
        width: 320px;
        right: 280px;
      }

      &--left {
        width: 320px;
        left: 280px;

        .c-timeline-step__container {
          order: 1;
        }
        .c-timeline-step__line {
          order: 2;
        }
      }

      @include media-breakpoint-down(xxl) {
        &--center {
          right: 190px;
        }

        &--right {
          right: 190px;
        }

        &--left {
          left: 190px;
        }
      }

      @include media-breakpoint-down(xl) {
        &--center {
          right: 100px;
        }

        &--right {
          right: 100px;
        }

        &--left {
          left: 100px;
        }
      }

      @include media-breakpoint-down(lg) {
        &--center {
          right: -20px;
        }

        &--right {
          right: -20px;
        }

        &--left {
          left: -20px;
        }
      }

      @include media-breakpoint-down(md) {
        position: static;
        //transform: translateX(-50%);
        justify-content: center;
        width: 100%;
        margin-bottom: 2rem;

        &__line {
          display: none;
        }
      }

      // 01 前置作業
      &--01 {
        .c-timeline-step__line {
          height: 1153px;
        }
      }

      // 02 內容規劃
      &--02 {
        top: 305px;

        .c-timeline-step__line {
          height: 426px;
        }
      }

      // 03 設計開工
      &--03 {
        top: 610px;

        .c-timeline-step__card {
          top: 150px;
        }

        .c-timeline-step__line {
          height: 543px;

          .c-line__mark {
            top: 120px;
          }
        }
      }

      // 04 程式開發
      &--04 {
        top: 915px;

        .c-timeline-step__line {
          height: 757px;
        }

        .c-line, .card-header {
          background-color: $gray-800 !important;
        }
      }

      // 05 測試上線
      &--05 {
        top: 1220px;

        .c-timeline-step__line {
          height: 697px;
        }
      }

      // 06 網站維運
      &--06 {
        top: 1825px;

        .c-timeline-step__line {
          height: 338px;
        }
      }
    }

    .c-timeline-fragment {
      position: absolute;

      &__line {
        height: 100%;
      }

      &--1 {
        right: 480px;
        top: 455px;
        height: 70px;
      }

      &--2 {
        right: 480px;
        top: 1463px;
        height: 84px;
      }

      &--3 {
        right: 540px;
        top: 1996px;
        height: 45px;
      }

      @include media-breakpoint-down(lg) {
        &--1 {
          right: 390px;
        }

        &--2 {
          right: 390px;
        }

        &--3 {
          right: 450px;
        }
      }

      @include media-breakpoint-down(md) {
        &--1 {
          right: 270px;
        }

        &--2 {
          right: 270px;
        }

        &--3 {
          right: 330px;
        }
      }

      @include media-breakpoint-down(sm) {
        &--1 {
          right: 200px;
        }

        &--2 {
          right: 200px;
        }

        &--3 {
          right: 240px;
        }
      }

      @include media-breakpoint-down(xs) {
        display: none;
      }
    }

    .c-step-card {
      width: 255px;

      &__header {
        position: relative;
        font-size: 1.125rem;
        font-weight: 600;
      }

      &__title {
        margin-bottom: -5px; // 視覺對齊中線
      }

      &__no {
        position: absolute;
        top: -1rem;
        right: 2rem;
        font-size: 4.75rem;
        font-weight: 100;
      }

      &__list-item {
        line-height: 2.3;
      }
    }
  }

  .c-line {
    position: relative;
    width: 30px;
    border-radius: 50px;
    padding-top: 8px;
    padding-bottom: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);

    &__mark {
      position: sticky;
      margin-left: 7px;
      top: 100px;
      width: 16px;
      height: 16px;
      background-color: white;
      border-radius: 50%;
      z-index: 1;
    }

    &__step {
      position: absolute;
      margin-left: 7px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, .15);
    }

    &__end, &__start {
      position: absolute;
      margin-left: 7px;
      width: 16px;
      height: 16px;
      background-color: rgba(0, 0, 0, .15);
      border-radius: 50%;
    }

    &__end{
      bottom: 8px;
    }
  }
}

// SCSS file for PortfolioItem

.view-portfolio-item {
  .l-portfolio-item {
    img {
      max-width: 100%;
      height: auto !important;
    }

    .h-img-loading {
      overflow: hidden;

      img {
        filter: blur(40px);
      }
    }
  }
}

// SCSS file for PortfolioList

.view-portfolio-list {
    //
}

.view-serp-ranking {
    //
}

// SCSS file for ShareItem

.view-share-item {
    //
}

// SCSS file for ShareList

.view-share-list {
    //
}
