// Part of simular file.

.h-std-form {
  .form-control {
    border-radius: 19px;
    box-shadow: $box-shadow;
    border: none;
    padding-left: 1.25rem;
    padding-right: 1.25rem;

    &:hover {
      box-shadow: 0 7px 20px rgba(black, .15) !important;
    }
  }

  textarea.form-control {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .form-group > label {
    margin-bottom: 1rem;
  }
}
