
.h-outline-none {
  &:hover {
    box-shadow: none;
  }
}

.h-rounded {
  border-radius: 100px;
}

.rounded-lg {
  border-radius: $border-radius-2xl !important;
}

.anchor-offset {
  position: relative;
  top: -100px;
}

.h-relative-float {
  position: relative;
  z-index: 1;
}

.h-bg-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: $black;
  opacity: .7;
}

// RWD iframe Video
.rwd-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;

  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
