// Part of simular file.

.c-slider-nav {
  .c-button {
    position: absolute;
    top: 50%;

    &:focus {
      box-shadow: none;
    }

    &--circle {
      height: 42px;
      width: 42px;
      padding: 0.375rem;
      transition: all .5s;

      &:hover {
        opacity: 1 !important;
      }
    }
  }

  &--outside {
    .c-button {
      &--previous {
        left: 0;
        transform: translate(-100%, -50%);
      }

      &--next {
        right: 0;
        transform: translate(100%, -50%);
      }
    }
  }

  &--inside {
    .c-button {
      z-index: 5;

      &--previous {
        left: 0;
        transform: translate(50%, -50%);
      }

      &--next {
        right: 0;
        transform: translate(-50%, -50%);
      }
    }
  }
}
