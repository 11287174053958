/**
 * Part of earth project.
 *
 * @copyright  Copyright (C) 2018 {ORGANIZATION}. All rights reserved.
 * @license    GNU General Public License version 2 or later;
 */
a {
  transition: all 0.3s;
}

code {
  border-radius: 0.25rem;
  padding: 0.25rem;
  font-size: 0.8em;
  background: #f0efed;
}

.v-truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--v-truncate-lines, 5);
  overflow: hidden;
}

.article-content {
  line-height: 1.75;
  font-size: 18px;
}
.article-content.h-blog-content {
  font-size: 20px;
}
.article-content.h-blog-content img {
  border: 1px solid rgba(0, 0, 0, 0.075);
}
.article-content img {
  max-width: 100%;
  height: auto;
}
.article-content h1, .article-content h2, .article-content h3, .article-content h4, .article-content h5, .article-content h6 {
  margin-top: 1.55em;
  margin-bottom: 0.8em;
  font-weight: bold;
  scroll-margin-top: 50px;
}
.article-content h1 {
  font-size: 2.25em;
}
.article-content h2 {
  font-size: 1.8em;
  color: #ff6666;
}
.article-content h2:where(h1 + *), .article-content h2:where(.c-article-delimiter + *) {
  margin-top: 0;
}
.article-content h3 {
  font-size: 1.5em;
}
.article-content h3:where(h2 + *), .article-content h3:where(.c-article-delimiter + *) {
  margin-top: 0;
}
.article-content h4 {
  font-size: 1.25em;
  color: #999999;
}
.article-content h4:where(h3 + *), .article-content h4:where(.c-article-delimiter + *) {
  margin-top: 0;
}
.article-content h5 {
  font-size: 1em;
}
.article-content h5:where(h5 + *), .article-content h5:where(.c-article-delimiter + *) {
  margin-top: 0;
}
.article-content h6 {
  font-size: 0.875em;
}
.article-content p {
  margin-top: 1em;
  margin-bottom: 1em;
}
.article-content li > p {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.article-content blockquote {
  padding-left: 1rem;
  border-left: 3px solid #999999;
  font-style: italic;
  color: #666;
  font-size: 1em;
}
.article-content hr {
  margin-top: 50px;
  margin-bottom: 50px;
}
.article-content pre {
  margin-top: 35px;
  margin-bottom: 35px;
}
.article-content pre code {
  border-radius: 0.25rem;
  background-color: #333333;
  padding: 2rem;
  font-size: 0.875em;
  display: block;
  color: white;
  overflow-x: scroll;
}
.article-content figure {
  text-align: center;
}
.article-content .c-article-delimiter {
  width: 100%;
}
.article-content .c-article-delimiter::before {
  padding-top: 1rem;
  padding-bottom: 1rem;
  content: "***";
  display: block;
  font-size: 2rem;
  line-height: 1.6em;
  letter-spacing: 0.5em;
  text-align: center;
}
.article-content .hn-table {
  display: block;
  width: 100%;
  overflow-x: auto;
  margin-top: 2em;
  margin-bottom: 2em;
}
.article-content .hn-table table td {
  min-width: 200px;
}
.article-content .hn-table thead > tr > td {
  font-weight: bolder;
}
.article-content figure footer {
  margin-top: 0.5rem;
  color: var(--bs-gray-600);
  font-size: 0.875em;
}
.article-content figure footer a {
  color: var(--bs-gray-700);
  text-decoration: underline;
}
.article-content img.image--center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.h-outline-none:hover {
  box-shadow: none;
}

.h-rounded {
  border-radius: 100px;
}

.rounded-lg {
  border-radius: 2rem !important;
}

.anchor-offset {
  position: relative;
  top: -100px;
}

.h-relative-float {
  position: relative;
  z-index: 1;
}

.h-bg-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0.7;
}

.rwd-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}
.rwd-video iframe, .rwd-video object, .rwd-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.btn {
  transition: all 0.35s;
}
.btn.shadow {
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.07) !important;
}
.btn.shadow:hover {
  box-shadow: 0 7px 10px rgba(0, 0, 0, 0.14) !important;
}

.btn-xs {
  --bs-btn-padding-y: 0.2rem;
  --bs-btn-padding-x: 0.875rem;
  --bs-btn-font-size: 0.75rem;
  --bs-btn-border-radius: 50px;
}

.btn-primary:hover {
  background-color: #E65C5C;
  border-color: #E65C5C;
}

.btn-white {
  --bs-btn-color: #fff;
  --bs-btn-bg: #fcfcfc;
  --bs-btn-border-color: #fcfcfc;
  --bs-btn-hover-color: #f2f2f2;
  --bs-btn-hover-bg: #f2f2f2;
  --bs-btn-hover-border-color: #f2f2f2;
  --bs-btn-focus-shadow-rgb: 252, 252, 252;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #cacaca;
  --bs-btn-active-border-color: #bdbdbd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #fcfcfc;
  --bs-btn-disabled-border-color: #fcfcfc;
  color: #ff6666;
}
.btn-white:hover, .btn-white:focus {
  color: #ff4d4d;
}

.btn-outline-white {
  --bs-btn-color: white;
  --bs-btn-border-color: white;
  --bs-btn-hover-color: white;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #fff;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fff;
  --bs-btn-active-border-color: #fff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: white;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: white;
  --bs-gradient: none;
  color: white;
}
.btn-outline-white:hover {
  color: #ff4d4d;
}

.btn-secondary:hover, .btn-outline-secondary:hover {
  background-color: #FCFCFC;
  border-color: #FCFCFC;
}

.h-std-card {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.07);
  border-radius: 2rem;
  border: none;
}
.h-std-card .card-header, .h-std-card .card-img-top {
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
}
.h-std-card .card-header {
  padding: 2rem;
}
.h-std-card .card-body {
  padding: 1.25rem 2rem;
}

.c-slider-nav .c-button {
  position: absolute;
  top: 50%;
}
.c-slider-nav .c-button:focus {
  box-shadow: none;
}
.c-slider-nav .c-button--circle {
  height: 42px;
  width: 42px;
  padding: 0.375rem;
  transition: all 0.5s;
}
.c-slider-nav .c-button--circle:hover {
  opacity: 1 !important;
}
.c-slider-nav--outside .c-button--previous {
  left: 0;
  transform: translate(-100%, -50%);
}
.c-slider-nav--outside .c-button--next {
  right: 0;
  transform: translate(100%, -50%);
}
.c-slider-nav--inside .c-button {
  z-index: 5;
}
.c-slider-nav--inside .c-button--previous {
  left: 0;
  transform: translate(50%, -50%);
}
.c-slider-nav--inside .c-button--next {
  right: 0;
  transform: translate(-50%, -50%);
}

.shadow-hover {
  transition: box-shadow 0.5s;
}
.shadow-hover.shadow-sm:hover {
  transition: box-shadow 0.3s;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05), 0 1px 1px 0 rgba(0, 0, 0, 0.1) !important;
}

.h-std-form .form-control {
  border-radius: 19px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.07);
  border: none;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.h-std-form .form-control:hover {
  box-shadow: 0 7px 20px rgba(0, 0, 0, 0.15) !important;
}
.h-std-form textarea.form-control {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.h-std-form .form-group > label {
  margin-bottom: 1rem;
}

.c-blog-cover {
  aspect-ratio: 1.9047619048;
  object-fit: cover;
}

html {
  overflow-x: hidden;
}

body {
  background-color: #f9f9f9;
}

.l-wrapper {
  overflow-x: hidden;
}
.h-no-transitions * {
  transition: none !important;
}

#bottom-bar {
  gap: 8px;
}

.c-main-navbar {
  background: transparent;
  margin-top: 30px;
  transition: all 0.5s;
}
.c-main-navbar.h-hide {
  margin-top: -100px;
}
.c-main-navbar .navbar-brand {
  margin-bottom: 0;
  padding: 0;
}
.c-main-navbar .navbar-nav .nav-link {
  padding-left: 1.15rem;
  padding-right: 1.15rem;
}
.c-main-navbar__menu a:not(.active), .c-main-navbar__menu button:not(:active), .c-main-navbar__menu .nav-link:not(.active) {
  color: #333333 !important;
}
.c-main-navbar__menu a:not(.active):hover, .c-main-navbar__menu a:not(.active):focus, .c-main-navbar__menu button:not(:active):hover, .c-main-navbar__menu button:not(:active):focus, .c-main-navbar__menu .nav-link:not(.active):hover, .c-main-navbar__menu .nav-link:not(.active):focus {
  color: rgba(51, 51, 51, 0.75) !important;
}

.c-mainmenu .dropdown-menu {
  border: none;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.07);
  border-radius: 1rem;
  padding: 1.2rem 1rem 1.5rem;
  margin-top: 1rem;
}
.c-mainmenu .dropdown-fade .dropdown > .dropdown-menu {
  transition-delay: 0.5s, 0.5s, 0.25s;
}
.c-mainmenu .dropdown-fade .dropdown:hover > .dropdown-menu {
  transition-delay: 0s, 0s, 0s;
}
.c-mainmenu .c-mega-subitem li {
  width: 100%;
}
.c-mainmenu .dropdown-item {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0.5rem;
}

.c-mobile-menu-toggler {
  margin-right: -1rem;
}
.c-mobile-menu-toggler:hover, .c-mobile-menu-toggler:focus {
  box-shadow: none;
}
.c-mobile-menu-toggler__icon {
  transform: scale(1.5);
  width: 20px;
}

.c-mobile-menu .subnav-link--nolink {
  color: #999999;
}

.c-banner {
  padding-top: 7rem;
  color: white;
  position: relative;
  isolation: isolate;
}
.c-banner__content {
  position: relative;
  z-index: 1;
}
.c-banner__overlay {
  position: absolute;
}
.c-banner--placeholder {
  height: 9rem;
  width: 100%;
}

.c-banner-title {
  position: relative;
}
.c-banner-title__image {
  position: absolute;
  height: 560px;
  width: 1185px;
  background-size: contain;
  background: transparent no-repeat center center;
}
.c-banner-title__content {
  min-height: 400px;
  mix-blend-mode: color-dodge;
}
.c-banner-title--right {
  overflow-x: hidden;
  padding-bottom: 200px;
  margin-bottom: -200px;
}
.c-banner-title--right .c-banner-title__image {
  left: 39vw;
  top: -50px;
  background-image: url(../../images/layout/banner-shapes-right.svg);
}
@media (max-width: 767.98px) {
  .c-banner-title--right .c-banner-title__content {
    margin-top: 100px;
  }
  .c-banner-title--right .c-banner-title__content h1 {
    font-size: 3.75rem;
  }
  .c-banner-title--right .c-banner-title__image {
    left: 10vw;
    top: -130px;
  }
}
.c-banner-title--left .c-banner-title__image {
  right: 45vw;
  top: -70px;
  background-image: url(../../images/layout/banner-shapes-left.svg);
}
.c-banner-title--left .c-banner-title__content {
  margin-left: auto;
}
@media (max-width: 767.98px) {
  .c-banner-title--left .c-banner-title__content {
    margin-top: 100px;
  }
  .c-banner-title--left .c-banner-title__content h1 {
    font-size: 3.75rem;
  }
  .c-banner-title--left .c-banner-title__image {
    right: 10vw;
    top: -200px;
  }
}

.l-small-banner {
  min-height: 350px;
  border-bottom-right-radius: 500px;
  padding-bottom: 40px;
}
@media (max-width: 767.98px) {
  .l-small-banner {
    border-bottom-right-radius: 0;
  }
}

.l-footer-wrapper {
  overflow: hidden;
}
.l-footer-wrapper--simple .l-footer {
  margin-top: 10rem;
  border-top-left-radius: 385px;
}
.l-footer-wrapper--cta .l-footer {
  margin-top: -230px;
  padding-top: 200px;
}

.l-footer {
  --top-rounded: 50px;
  position: relative;
  overflow: hidden;
  border-top-left-radius: var(--top-rounded);
  border-top-right-radius: var(--top-rounded);
}
.l-footer__logo {
  margin-top: -50px;
}
.l-footer__overlay {
  top: -555px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
}
.l-footer__overlay img {
  opacity: 0.35;
}
.l-footer__copyright {
  font-size: 0.625rem;
  font-weight: 300;
  letter-spacing: 1px;
}

.c-footer-menu {
  color: #4d4d4d !important;
}
.c-footer-menu .nav-link {
  padding-left: 0;
  color: #4d4d4d !important;
}
.c-footer-menu .nav-link:hover, .c-footer-menu .nav-link:focus {
  color: #ff6666 !important;
}
.c-footer-menu .nav-link.active {
  color: #ff6666 !important;
}

.l-contact {
  position: relative;
  margin-top: 200px;
}
.l-contact__shape {
  position: absolute;
  top: 0px;
  right: -450px;
  transform: scale(1.5) rotateZ(17deg);
  width: 100%;
}
.l-contact__shape > div {
  height: 600px;
  background: url(../../images/pages/home/float-shape-2.svg) no-repeat center center;
  transition: transform 0.1s;
}
.l-contact__mount {
  position: absolute;
  top: -350px;
  width: 100%;
}
.l-contact__mount > div {
  background: url(../../images/pages/home/bg-mount.webp) no-repeat center center;
  height: 800px;
  transition: transform 0.1s;
}
@media (max-width: 991.98px) {
  .l-contact__mount {
    top: -300px;
    background-size: 140%;
  }
}
.l-contact__body {
  position: relative;
  z-index: 1;
  height: 600px;
  color: white;
  background: url(../../images/pages/home/red-shape.svg) no-repeat center center;
}
@media (max-width: 767.98px) {
  .l-contact {
    margin-top: 200px;
  }
  .l-contact__shape {
    top: -200px;
    right: -100px;
    height: 400px;
  }
  .l-contact__shape > div {
    background-size: contain;
  }
  .l-contact__mount {
    top: -450px;
  }
  .l-contact__mount > div {
    background-size: contain;
  }
}

.l-promo-sections--as-footer .l-section--advantage {
  padding-bottom: 500px !important;
  margin-bottom: -800px;
}
.l-promo-sections .l-section .c-red-btn {
  font-size: 20px;
  min-width: 350px;
  margin-top: 32px;
  background-color: #ff6666;
  color: white;
  padding: 9px 0;
}
.l-promo-sections .l-section .c-red-btn:hover {
  background-color: #e65c5c;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  color: white;
}
@media (max-width: 576px) {
  .l-promo-sections .l-section .c-red-btn {
    width: 100%;
    min-width: unset;
  }
}
.l-promo-sections .l-section .c-outline-red-btn {
  font-size: 20px;
  min-width: 350px;
  margin-top: 32px;
  border: 1px solid white;
  background-color: #ff6666;
  color: white;
  padding: 9px 0;
}
.l-promo-sections .l-section .c-outline-red-btn:hover {
  background-color: white;
  color: #ff6666;
}
@media (max-width: 992px) {
  .l-promo-sections .l-section .c-outline-red-btn {
    margin-top: 1rem;
  }
}
@media (max-width: 576px) {
  .l-promo-sections .l-section .c-outline-red-btn {
    width: 100%;
    min-width: unset;
  }
}
.l-promo-sections .l-section .c-white-btn {
  font-size: 20px;
  min-width: 350px;
  margin-top: 32px;
  background-color: white;
  color: #ff6666;
  padding: 9px 0;
}
.l-promo-sections .l-section .c-white-btn:hover {
  color: #ff6666 !important;
  background-color: #F2F2F2 !important;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
}
@media (max-width: 576px) {
  .l-promo-sections .l-section .c-white-btn {
    width: 100%;
    min-width: unset;
  }
}
@media (max-width: 1200px) and (min-width: 992px) {
  .l-promo-sections .l-section .c-white-btn {
    width: 100%;
    min-width: unset;
  }
}
.l-promo-sections .l-section .l-title-wrap {
  position: relative;
  z-index: 3;
  text-align: center;
}
@media (max-width: 992px) {
  .l-promo-sections .l-section .l-title-wrap {
    text-align: left;
  }
}
.l-promo-sections .l-section .c-title {
  font-size: 42px;
  font-weight: 500;
  color: #333333;
}
@media (max-width: 576px) {
  .l-promo-sections .l-section .c-title {
    font-size: 2rem;
  }
}
.l-promo-sections .l-section .c-subtitle {
  line-height: 38px;
}
.l-promo-sections .l-section .service-card-mb {
  margin-bottom: 72px;
}
@media (max-width: 992px) {
  .l-promo-sections .l-section .service-card-mb {
    margin-bottom: 40px;
  }
}
.l-promo-sections .l-section .c-info-card__body {
  padding: 0 12px;
}
.l-promo-sections .l-section .c-info-card__title {
  margin-top: 1rem;
  font-size: 24px;
  line-height: 38px;
  font-weight: 500;
  color: #4d4d4d;
}
.l-promo-sections .l-section .c-info-card__subtitle {
  margin-top: 12px;
  font-size: 14px;
  line-height: 24px;
  color: #999999;
}
.l-promo-sections .l-section--cases {
  padding-top: 96px;
  padding-bottom: 120px;
  background-color: #f9f9f9;
  position: relative;
  z-index: 9;
}
@media (max-width: 992px) {
  .l-promo-sections .l-section--cases {
    padding-top: 48px;
    padding-bottom: 48px;
    z-index: unset;
  }
}
.l-promo-sections .l-section--cases .bg-industry3 {
  max-width: 57px;
  position: absolute;
  top: 1422px;
  right: 35%;
  z-index: 1;
}
@media (max-width: 1440px) {
  .l-promo-sections .l-section--cases .bg-industry3 {
    top: 1704px;
  }
}
@media (max-width: 992px) {
  .l-promo-sections .l-section--cases .bg-industry3 {
    display: none;
  }
}
.l-promo-sections .l-section--cases .portfolio-swiper {
  width: 100%;
  height: auto;
}
.l-promo-sections .l-section--cases .portfolio-swiper .swiper-slide {
  width: 100%;
  height: auto;
  border-radius: 1rem;
}
@media (max-width: 576px) {
  .l-promo-sections .l-section--cases .portfolio-swiper .swiper-slide {
    border-radius: 2rem;
  }
}
.l-promo-sections .l-section--cases .portfolio-swiper .swiper-slide img {
  width: 100%;
  height: auto;
  border-radius: 2rem;
}
.l-promo-sections .l-section--cases .content-body {
  margin-top: 2rem;
  color: #4d4d4d;
  padding: 0 32px;
  line-height: 30px;
}
@media (max-width: 576px) {
  .l-promo-sections .l-section--cases .content-body {
    margin-top: 1rem;
    padding: 0 16px;
  }
}
.l-promo-sections .l-section--cases .content-body .slide-title {
  font-size: 28px;
  font-weight: 500;
  line-height: 38px;
}
.l-promo-sections .l-section--cases .swiper-pagination--portfolio {
  text-align: center;
  margin-top: 38px;
}
.l-promo-sections .l-section--cases .swiper-pagination-bullet {
  background-color: #D8D8D8;
  width: 20px;
  height: 5px;
  border-radius: 20px;
  opacity: 1;
}
.l-promo-sections .l-section--cases .swiper-pagination-bullet-active {
  width: 50px;
  background-color: #ff6666;
}
.l-promo-sections .l-section--cases .swiper-button-prev {
  left: 60px;
  margin-top: 0;
  padding: 3rem 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 32px;
  transform: translateY(-50%);
  opacity: 0;
  transition: 0.15s ease-in-out;
}
.l-promo-sections .l-section--cases .swiper-button-prev::after {
  font: var(--fa-font-solid);
  content: "\f060";
  color: white;
  font-size: 1.5rem;
}
.l-promo-sections .l-section--cases .swiper-button-next {
  right: 60px;
  margin-top: 0;
  padding: 3rem 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 32px;
  transform: translateY(-50%);
  opacity: 0;
  transition: 0.15s ease-in-out;
}
.l-promo-sections .l-section--cases .swiper-button-next::after {
  font: var(--fa-font-solid);
  content: "\f061";
  color: white;
  font-size: 1.5rem;
}
.l-promo-sections .l-section--cases .swiper-nav-show {
  opacity: 1;
}
.l-promo-sections .l-section--articles {
  padding: 96px 0;
  background-color: #6E96DB;
  position: relative;
  overflow: hidden;
}
@media (max-width: 992px) {
  .l-promo-sections .l-section--articles {
    overflow: unset;
    padding: 48px 0;
  }
}
.l-promo-sections .l-section--articles .article-swiper {
  width: 100%;
  height: auto;
}
.l-promo-sections .l-section--articles .article-swiper .swiper-slide {
  width: 100%;
  height: auto;
  border-radius: 1rem;
}
@media (max-width: 576px) {
  .l-promo-sections .l-section--articles .article-swiper .swiper-slide {
    border-radius: 2rem;
  }
}
.l-promo-sections .l-section--articles .swiper-pagination--article {
  text-align: center;
  margin-top: 38px;
}
.l-promo-sections .l-section--articles .swiper-pagination-bullet {
  background-color: #D8D8D8;
  width: 20px;
  height: 5px;
  border-radius: 20px;
  opacity: 1;
}
.l-promo-sections .l-section--articles .swiper-pagination-bullet-active {
  width: 50px;
  background-color: #ff6666;
}
.l-promo-sections .l-section--articles .swiper-button-prev {
  left: -50px;
  margin-top: 0;
  padding: 3rem 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 32px;
  transform: translateY(-50%);
  transition: 0.15s ease-in-out;
}
.l-promo-sections .l-section--articles .swiper-button-prev::after {
  font: var(--fa-font-solid);
  content: "\f060";
  color: white;
  font-size: 1.5rem;
}
.l-promo-sections .l-section--articles .swiper-button-next {
  right: -50px;
  margin-top: 0;
  padding: 3rem 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 32px;
  transform: translateY(-50%);
  transition: 0.15s ease-in-out;
}
.l-promo-sections .l-section--articles .swiper-button-next::after {
  font: var(--fa-font-solid);
  content: "\f061";
  color: white;
  font-size: 1.5rem;
}
.l-promo-sections .l-section--articles .swiper-nav-show {
  opacity: 1;
}
.l-promo-sections .l-section--articles .c-article-card {
  position: relative;
  border-radius: 32px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
  background-color: white;
  height: 100%;
  overflow: hidden;
}
.l-promo-sections .l-section--articles .c-article-card__body {
  padding: 32px;
}
.l-promo-sections .l-section--articles .c-article-card .card-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  color: #333333;
  margin-bottom: 8px;
}
.l-promo-sections .l-section--articles .c-article-card .card-info {
  font-size: 14px;
}
.l-promo-sections .l-section--articles__actions {
  font-size: 20px;
  color: white;
  padding: 9px 0;
}
.l-promo-sections .l-section--articles__actions:hover, .l-promo-sections .l-section--articles__actions:focus {
  background-color: white;
  color: #6E96DB;
}
.l-promo-sections .l-section--articles .bg-article1 {
  max-width: 54px;
  position: absolute;
  top: 1700px;
  right: 25%;
  z-index: 9;
}
@media (max-width: 1440px) {
  .l-promo-sections .l-section--articles .bg-article1 {
    top: 1850px;
  }
}
@media (max-width: 1280px) {
  .l-promo-sections .l-section--articles .bg-article1 {
    top: 2035px;
  }
}
@media (max-width: 992px) {
  .l-promo-sections .l-section--articles .bg-article1 {
    top: 2035px;
    right: 15%;
  }
}
@media (max-width: 576px) {
  .l-promo-sections .l-section--articles .bg-article1 {
    top: 2536px;
    right: 5%;
  }
}
.l-promo-sections .l-section--articles .bg-article2 {
  max-width: 56px;
  position: absolute;
  top: 2225px;
  left: 25%;
  z-index: 9;
}
@media (max-width: 1440px) {
  .l-promo-sections .l-section--articles .bg-article2 {
    top: 2500px;
  }
}
@media (max-width: 1280px) {
  .l-promo-sections .l-section--articles .bg-article2 {
    top: 2700px;
  }
}
@media (max-width: 992px) {
  .l-promo-sections .l-section--articles .bg-article2 {
    top: -600px;
    left: 15%;
  }
}
@media (max-width: 576px) {
  .l-promo-sections .l-section--articles .bg-article2 {
    top: -430px;
    left: 10%;
  }
}
@media (max-width: 380px) {
  .l-promo-sections .l-section--articles .bg-article2 {
    top: 3422px;
    left: 10%;
  }
}
.l-promo-sections .l-section--advantage {
  position: relative;
  padding-top: 96px;
  padding-bottom: 120px;
  background-color: #ebebeb;
}
@media (max-width: 992px) {
  .l-promo-sections .l-section--advantage {
    padding-top: 48px;
    overflow: hidden;
  }
}
@media (max-width: 768px) {
  .l-promo-sections .l-section--advantage {
    padding-bottom: 80px;
  }
}
@media (max-width: 576px) {
  .l-promo-sections .l-section--advantage {
    padding-bottom: 50px;
  }
}
.l-promo-sections .l-section--advantage .c-advantage-cards-row {
  margin-top: 64px;
  position: relative;
  z-index: 3;
}
@media (max-width: 992px) {
  .l-promo-sections .l-section--advantage .c-advantage-cards-row {
    margin-top: 0;
  }
}
.l-promo-sections .l-section--advantage .c-advantage-card {
  background-color: white;
  position: relative;
  margin-left: 19px;
  padding: 22px 24px 46px 24px;
  border-radius: 0.5rem;
  box-shadow: 0px 36px 20px -16px rgba(0, 0, 0, 0.06);
  height: 100%;
}
@media (max-width: 992px) {
  .l-promo-sections .l-section--advantage .c-advantage-card {
    margin-left: 1rem;
    height: auto;
  }
}
.l-promo-sections .l-section--advantage .c-advantage-card:first-child {
  margin-left: 0;
}
.l-promo-sections .l-section--advantage .c-advantage-card__header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.l-promo-sections .l-section--advantage .c-advantage-card__header .deco-hole {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #EBEBEB;
  margin-right: 1.5rem;
}
.l-promo-sections .l-section--advantage .c-advantage-card__header .deco-hole:last-child {
  margin-right: 0;
}
.l-promo-sections .l-section--advantage .c-advantage-card__body {
  margin-top: 37px;
}
.l-promo-sections .l-section--advantage .c-advantage-card .card-title {
  font-size: 24px;
  font-weight: 700;
  color: #4D4D4D;
  margin-bottom: 12px;
}
.l-promo-sections .l-section--advantage .c-advantage-card .card-info {
  color: #999999;
}
.l-promo-sections .l-section--advantage .c-advantage-card .bg-tape {
  position: absolute;
  width: 56px;
  top: -34px;
  left: 50%;
  transform: translateX(-50%);
}
.l-promo-sections .l-section--advantage .bg-advantage {
  position: absolute;
  top: -72px;
  right: 0;
  z-index: 1;
}
@media (max-width: 1400px) {
  .l-promo-sections .l-section--advantage .bg-advantage {
    width: 75%;
  }
}
@media (max-width: 992px) {
  .l-promo-sections .l-section--advantage .bg-advantage {
    width: 120%;
    position: static;
    transform: translateX(10%);
  }
}
@media (max-width: 768px) {
  .l-promo-sections .l-section--advantage .bg-advantage {
    width: 110%;
    position: static;
    transform: translateX(10%);
  }
}
@media (max-width: 576px) {
  .l-promo-sections .l-section--advantage .bg-advantage {
    width: 130%;
    position: static;
    transform: translateX(-10%);
  }
}
.l-promo-sections .l-section--advantage .bg-advantage2 {
  max-width: 61.35px;
  position: absolute;
  top: 1920px;
  right: 5%;
  z-index: 0;
}
@media (max-width: 1440px) {
  .l-promo-sections .l-section--advantage .bg-advantage2 {
    top: 2300px;
  }
}
@media (max-width: 1280px) {
  .l-promo-sections .l-section--advantage .bg-advantage2 {
    top: 2380px;
  }
}
@media (max-width: 992px) {
  .l-promo-sections .l-section--advantage .bg-advantage2 {
    display: none;
  }
}

.c-partner-item .card-body {
  position: relative;
  padding: 100% 0 0;
}
.c-partner-item .card-body img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 80% !important;
  width: 80% !important;
  object-fit: contain;
  filter: grayscale(100);
  transition: all 0.5s;
}
.c-partner-item:hover img {
  filter: grayscale(0);
}

.c-partners-slider-control button:hover {
  color: #ff6666 !important;
}

.c-contact-info {
  cursor: pointer;
}
.c-contact-info .card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 220px;
}

.l-contact-info__address .c-contact-info__content > div {
  height: 1.5rem;
  transition: height 0.3s;
  max-width: 150px;
}
.l-contact-info__address:hover .c-contact-info__content > div {
  height: 3rem;
  white-space: normal !important;
  word-wrap: break-word !important;
}

.view-share-list .c-banner {
  border-bottom-right-radius: 400px;
}
.l-share-sidebar {
  top: 50px;
}
.l-share-sidebar::-webkit-scrollbar {
  width: 5px;
}
.l-share-sidebar::-webkit-scrollbar-thumb {
  width: 5px;
  background: rgba(0, 0, 0, 0);
  transition: all 0.3s;
}
.l-share-sidebar:hover::-webkit-scrollbar-thumb {
  width: 5px;
  background: #ff6666;
}

.article-item {
  z-index: 1;
}
.article-item .article-image {
  position: relative;
}
.article-item .article-image div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center center;
}
@media (max-width: 767.98px) {
  .article-item .article-image div {
    position: static;
    padding-top: 54%;
  }
}

.c-article-item.h-std-card img {
  transition: 0.3s all ease;
}
.c-article-item.h-std-card:hover img {
  transform: scale(1.1);
}

.c-finance-banner {
  position: relative;
  height: 864px;
  background: #FF6666;
  overflow: hidden;
  transition: 1s;
}
@media (min-width: 1600px) {
  .c-finance-banner {
    height: 949px;
  }
}
@media (max-width: 992px) {
  .c-finance-banner {
    height: 1221px;
  }
}
@media (max-width: 768px) {
  .c-finance-banner {
    height: 1000px;
  }
}
@media (max-width: 400px) {
  .c-finance-banner {
    height: 912px;
  }
}
.c-finance-banner__row {
  padding-top: 160px;
}
@media (max-width: 1200px) {
  .c-finance-banner__row {
    align-items: center;
  }
}
@media (max-width: 992px) {
  .c-finance-banner__row {
    padding-top: 144px;
    position: relative;
    z-index: 5;
  }
}
.c-finance-banner__content {
  padding-top: 32px;
}
@media (max-width: 992px) {
  .c-finance-banner__content {
    padding-top: 0;
  }
}
.c-finance-banner__content .banner-subtitle {
  font-size: 1.25rem;
  font-weight: 500;
}
.c-finance-banner__content .banner-title {
  font-size: 64px;
  font-weight: 300;
}
@media (max-width: 992px) {
  .c-finance-banner__content .banner-title {
    font-size: 48px;
  }
}
.c-finance-banner__content .banner-info {
  margin-top: 20px;
  line-height: 32px;
}
.c-finance-banner .bg-banner-text {
  position: absolute;
  z-index: 0;
  top: 150px;
  left: 0;
  width: 644px;
  height: auto;
}
.c-finance-banner .c-chatbox {
  text-align: center;
  width: fit-content;
  position: absolute;
  right: 396px;
  top: -29px;
  z-index: 11;
}
@media (min-width: 1600px) {
  .c-finance-banner .c-chatbox {
    right: 303px;
    top: -41px;
  }
}
@media (min-width: 1700px) {
  .c-finance-banner .c-chatbox {
    right: 420px;
    top: -10px;
  }
}
.c-finance-banner .c-chatbox__content {
  position: relative;
  padding: 6px 12px 6px 14px;
  border-radius: 1rem;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 12px;
}
.c-finance-banner .c-chatbox__content:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
}
.c-finance-banner .c-chatbox__circle {
  position: relative;
  margin: 0 auto;
  width: 26px;
  height: 26px;
  border-radius: 50%;
}
.c-finance-banner .c-chatbox__circle:before {
  content: "";
  width: 12px;
  height: 12px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.c-finance-banner .chatbox1 .c-chatbox__content {
  background-color: #2577BE;
  color: white;
}
.c-finance-banner .chatbox1 .c-chatbox__content:before {
  border-top: 10px solid #2577BE;
}
.c-finance-banner .chatbox1 .c-chatbox__circle {
  background-color: rgba(36, 119, 191, 0.3);
}
.c-finance-banner .chatbox1 .c-chatbox__circle:before {
  background-color: #2577BE;
}
.c-finance-banner .chatbox2 .c-chatbox__content {
  background-color: #FFD940;
  color: #474747;
}
.c-finance-banner .chatbox2 .c-chatbox__content:before {
  border-top: 10px solid #FFD940;
}
.c-finance-banner .chatbox2 .c-chatbox__circle {
  background-color: rgba(250, 209, 60, 0.3);
}
.c-finance-banner .chatbox2 .c-chatbox__circle:before {
  background-color: #FBD03B;
}
.c-finance-banner .chatbox3 .c-chatbox__content {
  background-color: #FD9800;
  color: #F9F9F9;
}
.c-finance-banner .chatbox3 .c-chatbox__content:before {
  border-top: 10px solid #FD9800;
}
.c-finance-banner .chatbox3 .c-chatbox__circle {
  background-color: rgba(252, 151, 0, 0.3);
}
.c-finance-banner .chatbox3 .c-chatbox__circle:before {
  background-color: #FD9800;
}
.c-finance-banner .chatbox4 .c-chatbox__content {
  background-color: #204272;
  color: white;
}
.c-finance-banner .chatbox4 .c-chatbox__content:before {
  border-top: 10px solid #204272;
}
.c-finance-banner .chatbox4 .c-chatbox__circle {
  background-color: rgba(32, 67, 115, 0.3);
}
.c-finance-banner .chatbox4 .c-chatbox__circle:before {
  background-color: #204272;
}
.c-finance-banner .chatbox5 .c-chatbox__content {
  background-color: #F28A8A;
  color: white;
}
.c-finance-banner .chatbox5 .c-chatbox__content:before {
  border-top: 10px solid #F28A8A;
}
.c-finance-banner .chatbox5 .c-chatbox__circle {
  background-color: rgba(250, 209, 60, 0.3);
}
.c-finance-banner .chatbox5 .c-chatbox__circle:before {
  background-color: #FBD03B;
}
.c-finance-banner .bg-banner-wave {
  position: absolute;
  left: -17%;
  bottom: 0;
  transition: 1s;
  z-index: 1;
}
@media (min-width: 1600px) {
  .c-finance-banner .bg-banner-wave {
    left: -5%;
    bottom: 97px;
  }
}
@media (min-width: 1700px) {
  .c-finance-banner .bg-banner-wave {
    left: 0;
  }
}
@media (max-width: 992px) {
  .c-finance-banner .bg-banner-wave {
    bottom: -117px;
  }
}
@media (max-width: 400px) {
  .c-finance-banner .bg-banner-wave {
    left: -86%;
  }
}
.c-finance-banner .bg-banner-wave img {
  height: 387px;
}
@media (min-width: 1700px) {
  .c-finance-banner .bg-banner-wave img {
    width: 110%;
    height: auto;
  }
}
@media (min-width: 1600px) {
  .c-finance-banner .bg-banner-wave img {
    width: 100%;
    height: auto;
  }
}
.c-finance-banner .banner-bottom {
  width: 101%;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
}
.c-finance-banner .bg-arrow {
  width: 64px;
  height: auto;
  position: absolute;
  right: 0;
  bottom: 96px;
  z-index: 3;
}
@media (max-width: 992px) {
  .c-finance-banner .bg-arrow {
    right: 8%;
    bottom: 5px;
  }
}
.c-finance-banner .l-browser-swiper {
  position: absolute;
  width: 100%;
  top: 17%;
}
@media (max-width: 992px) {
  .c-finance-banner .l-browser-swiper {
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
  }
}
.c-finance-banner .banner-swiper1,
.c-finance-banner .banner-swiper2 {
  width: 100%;
  height: 100%;
}
.c-finance-banner .banner-swiper1 .swiper-slide,
.c-finance-banner .banner-swiper2 .swiper-slide {
  width: 100%;
  height: fit-content;
  border-radius: 1rem;
}
.c-finance-banner .banner-swiper1 .swiper-slide img,
.c-finance-banner .banner-swiper2 .swiper-slide img {
  width: 100%;
  height: auto;
  border-radius: 1rem;
}
.c-finance-banner .l-left-bottom-swiper {
  width: fit-content;
  position: absolute;
  left: 12px;
  bottom: -65px;
  z-index: 5;
}
@media (min-width: 400px) {
  .c-finance-banner .l-left-bottom-swiper {
    left: 28px;
    bottom: -65px;
  }
}
@media (min-width: 576px) {
  .c-finance-banner .l-left-bottom-swiper {
    left: 20px;
    bottom: -95px;
  }
}
@media (min-width: 768px) {
  .c-finance-banner .l-left-bottom-swiper {
    left: 42px;
    bottom: -96px;
  }
}
@media (min-width: 992px) {
  .c-finance-banner .l-left-bottom-swiper {
    left: -20px;
    bottom: -128px;
  }
}
@media (min-width: 1200px) {
  .c-finance-banner .l-left-bottom-swiper {
    left: -13px;
    bottom: -117px;
  }
}
@media (min-width: 1600px) {
  .c-finance-banner .l-left-bottom-swiper {
    left: -30px;
    bottom: -111px;
  }
}
.c-finance-banner .l-left-bottom-swiper .banner-swiper3 {
  width: 210px;
  height: 155px;
}
@media (max-width: 576px) {
  .c-finance-banner .l-left-bottom-swiper .banner-swiper3 {
    width: 151px;
    height: 111.5px;
  }
}
.c-finance-banner .l-left-bottom-swiper .banner-swiper3 .swiper-slide {
  width: 100%;
  height: fit-content;
  border-radius: 1rem;
}
.c-finance-banner .l-left-bottom-swiper .banner-swiper3 .swiper-slide img {
  width: 100%;
  height: auto;
  border-radius: 1rem;
}
.c-finance-banner .l-bottom-swiper {
  width: fit-content;
  position: absolute;
  left: 136px;
  bottom: -91px;
  z-index: 5;
}
@media (min-width: 400px) {
  .c-finance-banner .l-bottom-swiper {
    left: 155px;
    bottom: -91px;
  }
}
@media (min-width: 576px) {
  .c-finance-banner .l-bottom-swiper {
    left: 187px;
    bottom: -162px;
  }
}
@media (min-width: 768px) {
  .c-finance-banner .l-bottom-swiper {
    left: 214px;
    bottom: -150px;
  }
}
@media (min-width: 992px) {
  .c-finance-banner .l-bottom-swiper {
    left: 151px;
    bottom: -200px;
  }
}
@media (min-width: 1200px) {
  .c-finance-banner .l-bottom-swiper {
    left: 157px;
    bottom: -196px;
  }
}
@media (min-width: 1600px) {
  .c-finance-banner .l-bottom-swiper {
    left: 128px;
    bottom: -190px;
  }
}
.c-finance-banner .l-bottom-swiper .banner-swiper4 {
  width: 131px;
  height: 133px;
}
@media (max-width: 576px) {
  .c-finance-banner .l-bottom-swiper .banner-swiper4 {
    width: 75px;
    height: 76px;
  }
}
.c-finance-banner .l-bottom-swiper .banner-swiper4 .swiper-slide {
  width: 100%;
  height: fit-content;
  border-radius: 1rem;
}
.c-finance-banner .l-bottom-swiper .banner-swiper4 .swiper-slide img {
  width: 100%;
  height: auto;
  border-radius: 1rem;
}
.c-finance-banner .bg-banner-line--left {
  position: absolute;
  width: 62.7px;
  left: 14px;
  top: 0;
  z-index: 9;
}
.c-finance-banner .bg-banner-line--bottom {
  position: absolute;
  width: 44px;
  left: 54px;
  top: 460px;
  z-index: 0;
}
@media (max-width: 992px) {
  .c-finance-banner .bg-banner-line--bottom {
    display: none;
  }
}
.c-finance-banner .bg-banner-line--right {
  position: absolute;
  width: 12px;
  right: 54px;
  top: 466px;
  z-index: 9;
}
@media (min-width: 1440px) {
  .c-finance-banner .bg-banner-line--right {
    right: 10%;
  }
}
@media (max-width: 576px) {
  .c-finance-banner .bg-banner-line--right {
    right: 25px;
    top: 790px;
  }
}
.c-finance-banner .bg-banner-line--right1 {
  position: absolute;
  width: 29px;
  right: 16px;
  top: 441px;
  z-index: 0;
}
@media (min-width: 1440px) {
  .c-finance-banner .bg-banner-line--right1 {
    right: 7%;
  }
}
@media (max-width: 992px) {
  .c-finance-banner .bg-banner-line--right1 {
    display: none;
  }
}

.swiper {
  visibility: hidden;
}
.swiper.swiper-initialized {
  visibility: visible;
}

.layout-solutions-finance-marketing .l-finance-page .l-section--feature {
  position: relative;
  padding-top: 140px;
  padding-bottom: 140px;
  margin-top: -240px;
  z-index: 1;
}
@media (min-width: 1600px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--feature {
    margin-top: -300px;
  }
}
@media (max-width: 992px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--feature {
    padding-bottom: 48px;
  }
}
@media (max-width: 768px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--feature {
    margin-top: -170px;
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--feature .img-feature {
  width: 130px;
  height: auto;
}
.layout-solutions-finance-marketing .l-finance-page .l-section--feature .feature-title {
  color: #4D4D4D;
}
.layout-solutions-finance-marketing .l-finance-page .l-section--feature .feature-subtitle {
  font-size: 14px;
  color: #999999;
  line-height: 24px;
}
.layout-solutions-finance-marketing .l-finance-page .l-section--feature .bg-feature {
  position: absolute;
  left: 50px;
  top: 250px;
  z-index: 9;
}
@media (max-width: 1440px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--feature .bg-feature {
    top: 210px;
  }
}
@media (max-width: 768px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--feature .bg-feature {
    display: none;
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--feature .bg-service2 {
  max-width: 73px;
  position: absolute;
  top: 846px;
  right: 10%;
  z-index: 9;
}
@media (min-width: 992px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--feature .bg-service2 {
    top: 579px;
  }
}
@media (min-width: 1200px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--feature .bg-service2 {
    top: 790px;
  }
}
@media (min-width: 1440px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--feature .bg-service2 {
    top: 579px;
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--service {
  position: relative;
  overflow: hidden;
  padding-bottom: 120px;
}
@media (max-width: 992px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--service {
    padding-top: 48px;
    padding-bottom: 48px;
    background-color: white;
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--service__row {
  margin-top: 1rem;
}
@media (min-width: 992px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--service__row {
    margin-top: 66px;
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--service .bg-service {
  width: 100%;
  height: auto;
  position: relative;
  left: 0;
  top: 0;
  z-index: 0;
  transform: translateX(-3%);
}
@media (min-width: 576px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--service .bg-service {
    width: 130%;
    transform: translateX(-19%);
  }
}
@media (min-width: 768px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--service .bg-service {
    width: 120%;
    transform: translateX(-18%);
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--service .img-service1 {
  width: 100%;
  height: auto;
  position: relative;
  left: 0;
  top: 0;
  transform: translateX(-3%);
  z-index: 1;
}
@media (min-width: 992px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--service .img-service1 {
    position: absolute;
    width: 132%;
    transform: translateX(-28%);
  }
}
@media (min-width: 1200px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--service .img-service1 {
    width: 120%;
    transform: translateX(-25%);
  }
}
@media (min-width: 1400px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--service .img-service1 {
    width: 100%;
    transform: translateX(-7%);
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--service .img-service2 {
  width: 100%;
  height: auto;
  position: relative;
  left: 0;
  top: 0;
  z-index: 0;
  transform: translateX(-3%);
}
@media (min-width: 992px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--service .img-service2 {
    position: absolute;
    top: 127px;
    width: 160%;
    transform: translateX(-39%);
  }
}
@media (min-width: 1200px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--service .img-service2 {
    top: 127px;
    width: 181%;
    transform: translateX(-49%);
  }
}
@media (min-width: 1400px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--service .img-service2 {
    width: 160%;
    transform: translateX(-41%);
  }
}
@media (min-width: 1600px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--service .img-service2 {
    width: 141%;
    transform: translateX(-32%);
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--service .bg-service-top {
  width: 15px;
  position: absolute;
  left: -5%;
  top: 207px;
  z-index: -1;
}
@media (max-width: 1440px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--service .bg-service-top {
    top: 287px;
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--service .bg-service-top img {
  width: 100%;
  height: auto;
}
.layout-solutions-finance-marketing .l-finance-page .l-section--service .bg-service-right {
  width: 40px;
  position: absolute;
  right: 19%;
  top: 650px;
}
@media (max-width: 1440px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--service .bg-service-right {
    top: 600px;
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--service .bg-service-right img {
  width: 100%;
  height: auto;
}
.layout-solutions-finance-marketing .l-finance-page .l-section--service .bg-service-bottom {
  width: 15px;
  position: absolute;
  left: 6%;
  top: 936px;
}
@media (max-width: 1440px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--service .bg-service-bottom {
    top: 1000px;
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--service .bg-service-bottom img {
  width: 100%;
  height: auto;
}
.layout-solutions-finance-marketing .l-finance-page .l-section--creative {
  position: relative;
  padding-top: 48px;
  background: #ff6666;
}
@media (min-width: 992px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--creative {
    border-top-left-radius: 224px;
    padding: 88px 0;
    background: #ff6666 url("../../images/pages/finance/img-sprint.png") no-repeat left bottom;
    background-size: 60%;
  }
}
@media (min-width: 1200px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--creative {
    padding: 120px 0;
    background-size: 57%;
  }
}
@media (min-width: 1500px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--creative {
    padding: 120px 0;
    background-size: 53%;
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--creative .sprint-info {
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 0.01rem;
  line-height: 32px;
}
.layout-solutions-finance-marketing .l-finance-page .l-section--creative .bg-sprint-left {
  max-width: 52px;
  position: absolute;
  left: 5%;
  top: 311px;
  z-index: 9;
}
@media (max-width: 1440px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--creative .bg-sprint-left {
    top: 389px;
  }
}
@media (max-width: 992px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--creative .bg-sprint-left {
    display: none;
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--creative .bg-sprint-right {
  max-width: 62px;
  position: absolute;
  right: 9%;
  top: 490px;
  z-index: 9;
}
@media (max-width: 1440px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--creative .bg-sprint-right {
    top: 580px;
  }
}
@media (max-width: 992px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--creative .bg-sprint-right {
    display: none;
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--creative .bg-sprint-bottom {
  max-width: 23px;
  position: absolute;
  right: 29%;
  top: 1000px;
  z-index: 9;
}
@media (max-width: 1440px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--creative .bg-sprint-bottom {
    top: 1100px;
  }
}
@media (max-width: 992px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--creative .bg-sprint-bottom {
    display: none;
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--interactive {
  position: relative;
  padding: 96px 0;
  max-width: 100%;
  overflow-x: hidden;
  background: #EBEBEB url("../../images/pages/finance/bg-case.png") no-repeat center bottom;
}
@media (max-width: 992px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--interactive {
    padding: 48px 0;
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--interactive .rwd-video--interactive {
  padding-top: 13px;
  z-index: 3;
}
@media (min-width: 1400px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--interactive .rwd-video--interactive {
    padding-bottom: 40.25%;
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--interactive .rwd-video--interactive video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  border: 0;
  z-index: 3;
  padding: 4.9% 12.5% 8.8%;
}
@media (min-width: 1400px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--interactive .rwd-video--interactive video {
    padding: 32px 21.5% 0 21.5%;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--interactive .rwd-video--interactive video {
    padding: 24px 9% 0 9%;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--interactive .rwd-video--interactive video {
    padding: 17px 8.5% 0 8.5%;
  }
}
@media (max-width: 992px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--interactive .rwd-video--interactive video {
    padding: 24px 9.5% 0 9.5%;
  }
}
@media (max-width: 768px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--interactive .rwd-video--interactive video {
    padding: 18px 8.5% 0 8.5%;
  }
}
@media (max-width: 460px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--interactive .rwd-video--interactive video {
    padding: 4% 7.9% 0 8%;
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--interactive .bg-laptop {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 3;
}
@media (min-width: 1400px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--interactive .bg-laptop img {
    width: 71%;
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--interactive .bg-case1 {
  display: none;
}
@media (min-width: 1024px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--interactive .bg-case1 {
    display: block;
    width: 100%;
    max-width: 405px;
    position: absolute;
    top: 20%;
    left: 72%;
    z-index: 0;
  }
}
@media (min-width: 1100px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--interactive .bg-case1 {
    left: 76%;
    z-index: 0;
  }
}
@media (min-width: 1200px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--interactive .bg-case1 {
    left: 79%;
    z-index: 0;
  }
}
@media (min-width: 1400px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--interactive .bg-case1 {
    left: 74%;
    z-index: 5;
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--interactive .bg-case2 {
  display: none;
}
@media (min-width: 1024px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--interactive .bg-case2 {
    display: block;
    width: 100%;
    max-width: 347px;
    position: absolute;
    top: -57px;
    left: 83%;
    z-index: 0;
  }
}
@media (min-width: 1100px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--interactive .bg-case2 {
    top: -57px;
    left: 86%;
  }
}
@media (min-width: 1400px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--interactive .bg-case2 {
    left: 79%;
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--interactive .bg-case3 {
  display: none;
}
@media (min-width: 1024px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--interactive .bg-case3 {
    display: block;
    width: 100%;
    max-width: 327px;
    position: absolute;
    top: -19px;
    left: -45%;
    z-index: 5;
  }
}
@media (min-width: 1200px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--interactive .bg-case3 {
    max-width: 386px;
    top: -19px;
    left: -37%;
  }
}
@media (min-width: 1400px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--interactive .bg-case3 {
    top: -19px;
    left: 0;
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--interactive .bg-case4 {
  display: none;
}
@media (min-width: 1024px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--interactive .bg-case4 {
    display: block;
    width: 100%;
    max-width: 234px;
    position: absolute;
    top: 48%;
    left: -36%;
    z-index: 5;
  }
}
@media (min-width: 1200px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--interactive .bg-case4 {
    max-width: 240px;
    top: 48%;
    left: -24%;
  }
}
@media (min-width: 1400px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--interactive .bg-case4 {
    max-width: 240px;
    top: 47%;
    left: 58px;
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--chart {
  position: relative;
  padding-top: 90px;
  padding-bottom: 100px;
  background: white url("../../images/pages/finance/bg-chart.png") no-repeat;
  background-size: cover;
}
@media (max-width: 992px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--chart {
    padding-top: 48px;
    padding-bottom: 48px;
    background: #FFC701;
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--chart .bg-chart2 {
  max-width: 64px;
  position: absolute;
  right: 15%;
  top: 595px;
  z-index: 1;
}
@media (max-width: 1440px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--chart .bg-chart2 {
    top: 595px;
  }
}
@media (max-width: 1280px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--chart .bg-chart2 {
    top: 780px;
  }
}
@media (max-width: 992px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--chart .bg-chart2 {
    display: none;
  }
}
@media (min-width: 992px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--chart .chart-title-wrap {
    padding-top: 70px;
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--flow {
  padding-top: 98px;
  padding-bottom: 120px;
  background-color: white;
}
@media (max-width: 992px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--flow {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--flow .flow-swiper {
  width: 100%;
  height: auto;
  padding-bottom: 15px;
}
.layout-solutions-finance-marketing .l-finance-page .l-section--flow .flow-swiper .swiper-slide {
  width: 100%;
  height: auto;
  border-radius: 1rem;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
}
@media (max-width: 576px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--flow .flow-swiper .swiper-slide {
    border-radius: 2rem;
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--flow .flow-swiper .swiper-slide img {
  width: 100%;
  height: auto;
  border-radius: 1rem;
}
@media (max-width: 576px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--flow .flow-swiper .swiper-slide img {
    border-radius: 2rem;
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--flow .swiper-pagination {
  bottom: -55px;
}
.layout-solutions-finance-marketing .l-finance-page .l-section--flow .swiper-pagination-bullet {
  background-color: #D8D8D8;
  width: 20px;
  height: 5px;
  border-radius: 20px;
  opacity: 1;
}
.layout-solutions-finance-marketing .l-finance-page .l-section--flow .swiper-pagination-bullet-active {
  width: 50px;
  background-color: #ff6666;
}
.layout-solutions-finance-marketing .l-finance-page .l-section--flow .swiper-button-prev {
  left: 60px;
  margin-top: 0;
  padding: 3rem 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 32px;
  transform: translateY(-50%);
  opacity: 0;
  transition: 0.15s ease-in-out;
}
@media (max-width: 450px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--flow .swiper-button-prev {
    padding: 2.25rem 10px;
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--flow .swiper-button-prev::after {
  font: var(--fa-font-solid);
  content: "\f060";
  color: white;
  font-size: 1.5rem;
}
.layout-solutions-finance-marketing .l-finance-page .l-section--flow .swiper-button-next {
  right: 60px;
  margin-top: 0;
  padding: 3rem 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 32px;
  transform: translateY(-50%);
  opacity: 0;
  transition: 0.15s ease-in-out;
}
@media (max-width: 450px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--flow .swiper-button-next {
    padding: 2.25rem 10px;
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--flow .swiper-button-next::after {
  font: var(--fa-font-solid);
  content: "\f061";
  color: white;
  font-size: 1.5rem;
}
.layout-solutions-finance-marketing .l-finance-page .l-section--flow .swiper-nav-show {
  opacity: 1;
}
.layout-solutions-finance-marketing .l-finance-page .l-section--flow .bg-step {
  max-width: 57px;
  position: absolute;
  top: 897px;
  left: 25%;
  z-index: 1;
}
@media (max-width: 1440px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--flow .bg-step {
    top: 1100px;
  }
}
@media (max-width: 992px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--flow .bg-step {
    display: none;
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--story {
  padding-top: 160px;
  height: 800px;
  background: white url("../../images/pages/finance/bg-story.png") no-repeat center;
}
@media (max-width: 992px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--story {
    padding-top: 48px;
    height: 700px;
    background: #FF6666 url("../../images/pages/finance/bg-story.png") no-repeat center bottom;
    background-size: 150%;
  }
}
@media (max-width: 768px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--story {
    background-size: 189%;
  }
}
@media (max-width: 576px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--story {
    background-size: 189%;
  }
}
@media (max-width: 400px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--story {
    background-size: 233%;
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--story .story-info {
  text-align: left;
  color: rgba(255, 255, 255, 0.8);
  line-height: 38px;
  width: 80%;
  margin: 8px auto 0;
}
@media (max-width: 992px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--story .story-info {
    width: 100%;
    margin: 8px 0 0 0;
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--industry {
  position: relative;
  background-color: white;
  padding-top: 82px;
  padding-bottom: 132px;
}
@media (max-width: 992px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--industry {
    overflow: hidden;
    padding-bottom: 90px;
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--industry .industry-type {
  font-size: 24px;
  color: #4d4d4d;
  margin-top: 24px;
}
.layout-solutions-finance-marketing .l-finance-page .l-section--industry .bg-industry1 {
  max-width: 62.7px;
  position: absolute;
  top: 1270px;
  right: 24%;
}
@media (max-width: 1440px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--industry .bg-industry1 {
    top: 1405px;
  }
}
@media (max-width: 992px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--industry .bg-industry1 {
    display: none;
  }
}
.layout-solutions-finance-marketing .l-finance-page .l-section--industry .bg-industry2 {
  max-width: 65px;
  position: absolute;
  top: 1390px;
  left: 8%;
  z-index: 9;
}
@media (max-width: 1440px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--industry .bg-industry2 {
    top: 1640px;
  }
}
@media (max-width: 992px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--industry .bg-industry2 {
    left: 5%;
    top: -685px;
  }
}
@media (max-width: 576px) {
  .layout-solutions-finance-marketing .l-finance-page .l-section--industry .bg-industry2 {
    left: 5%;
    top: 2340px;
  }
}
.layout-solutions-finance-marketing .chart-card {
  border-radius: 1rem;
  border: none;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
  padding: 2rem 2rem;
}
@media (min-width: 992px) {
  .layout-solutions-finance-marketing .chart-card {
    padding: 24px 40px;
  }
}
.layout-solutions-finance-marketing .form-group-text {
  color: #204272;
  font-size: 14px;
}
.layout-solutions-finance-marketing .form-group button {
  font-size: 18px;
  border-width: 2px;
}
.layout-solutions-finance-marketing .form-group .custom-select {
  color: #204272;
  font-weight: 500;
  border: 2px solid #204272;
  border-radius: 10px;
  display: inline-block;
  width: 100%;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 18px;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat #fff;
  border-radius: 10px;
  appearance: none;
}
.layout-solutions-finance-marketing .form-group .custom-select:focus {
  box-shadow: none;
  outline: none;
}
.layout-solutions-finance-marketing .c-chart-cover {
  background-color: #ffffff;
  width: 100px;
  height: 16px;
  position: absolute;
  left: 10px;
  bottom: 81px;
}
@media (min-width: 576px) {
  .layout-solutions-finance-marketing .c-chart-cover {
    bottom: 79px;
  }
}
@media (min-width: 992px) {
  .layout-solutions-finance-marketing .c-chart-cover {
    bottom: 70px;
  }
}
.layout-solutions-finance-marketing .amcharts-range-selector-range-wrapper,
.layout-solutions-finance-marketing .amcharts-range-selector-title {
  display: none;
}
.layout-solutions-finance-marketing .amcharts-range-selector-period-wrapper {
  display: flex;
  justify-content: flex-end;
}
.layout-solutions-finance-marketing .amcharts-range-selector-period-button {
  margin: 0 20px 0 0;
  padding: 0.5rem 1rem;
  background-color: white;
  color: #204272;
  border: 2px solid #204272;
  border-radius: 10px;
}
@media (max-width: 576px) {
  .layout-solutions-finance-marketing .amcharts-range-selector-period-button {
    margin: 0 1rem 0 0;
  }
}
.layout-solutions-finance-marketing .amcharts-range-selector-period-button:hover {
  background-color: #204272;
  color: white;
}
.layout-solutions-finance-marketing .amcharts-range-selector-period-button.active {
  background-color: #204272;
  color: white;
}
.layout-solutions-finance-marketing .amcharts-range-selector-period-button:last-child {
  margin: 0;
}
.layout-solutions-finance-marketing .l-footer-wrapper--cta {
  background: #EBEBEB;
}

.view-contact #input-item-budget-wrapper .card, .view-contact #input-item-budget-wrapper label {
  cursor: pointer;
  transition: 0.3s all;
}
.view-contact #input-item-budget-wrapper .card.active .card-body {
  transition: all 0.3s;
  box-shadow: 0 0 0 2px #ff6666;
  border-radius: 1rem;
}
.view-contact #input-item-budget-wrapper input[type=radio].is-valid {
  border-color: #999999;
}
.view-contact #input-item-budget-wrapper input[type=radio]:checked {
  background-color: #ff6666 !important;
}
.view-contact #input-item-budget-wrapper input[type=radio]:focus {
  box-shadow: none;
  border: #ff6666;
}

.view-home .l-main {
  overflow-x: hidden;
  padding-top: 100px;
  margin-top: -100px;
}
.view-home .c-banner {
  border-bottom-right-radius: 600px;
  overflow: hidden;
}
.view-home .c-banner__overlay {
  left: 50%;
  transform: translateX(-46%);
  bottom: -1rem;
}
@media (max-width: 767.98px) {
  .view-home .c-banner__content .display-1 {
    font-size: 3.75rem;
  }
  .view-home .c-banner__content .h3 {
    font-size: 1.5rem;
  }
}
.view-home .l-section--features {
  position: relative;
}
.view-home .l-section--features .c-float-shape {
  position: absolute;
  right: -30px;
  top: -75px;
}
.view-home .l-section--features .c-feature-item {
  transition: all 0.35s;
}
.view-home .l-section--features .c-feature-item svg g > g {
  transition: all 0.35s;
}
.view-home .l-section--features .c-feature-item:hover {
  color: #ff6666;
}
.view-home .l-section--features .c-feature-item:hover svg g > g {
  stroke: #ff6666;
}
.view-home .l-section--features .c-feature-item__text {
  font-size: 1.125rem;
}
.view-home .l-section--about {
  min-height: 250px;
}
@media (min-width: 992px) {
  .view-home .l-section--about {
    min-height: 500px;
  }
}
.view-home .l-section--about .l-section__overlay {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.view-home .l-section--about .l-section__overlay .c-float-line {
  transition: transform 0.3s;
}
@media (max-width: 991.98px) {
  .view-home .l-section--about .l-section__overlay .c-float-line svg {
    height: 300px;
  }
}
.view-home .l-works .owl-dots {
  margin-top: 1.5rem;
  text-align: center;
}
.view-home .l-works .owl-dots .owl-dot {
  width: 20px;
  height: 5px;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 30px;
  background-color: #d8d8d8;
  transition: all 0.3s;
}
.view-home .l-works .owl-dots .owl-dot.active {
  background-color: #ff6666 !important;
  width: 50px;
}
.view-home .l-works .owl-dots .owl-dot:hover, .view-home .l-works .owl-dots .owl-dot:focus {
  outline: none;
  background-color: #bfbfbf;
}
.view-home .l-works .c-slider-nav {
  width: 100%;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.view-home .l-works .c-slider-nav .c-button span {
  font-size: 2rem;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}
.view-home .l-works .c-slider-nav .c-button {
  top: auto;
  bottom: -20px;
  transform: none;
  height: 50px;
  width: 7rem;
  border-radius: 0 !important;
  border: none;
  opacity: 0.7;
  transition: all 0.3s;
}
.view-home .l-works .c-slider-nav .c-button:hover {
  opacity: 1;
}
.view-home .c-works-slider .owl-stage {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.view-home .c-work-item__description {
  opacity: 0;
  transition: opacity 0.35s;
}
.view-home .c-work-item__text {
  line-height: 2;
}
.view-home .owl-item.center .c-work-item__description {
  opacity: 1;
}
.view-home .l-serves {
  position: relative;
  margin-top: 250px;
}
@media (max-width: 767.98px) {
  .view-home .l-serves {
    margin-top: 0;
  }
}
.view-home .l-serves .c-float-shape {
  position: absolute;
  left: 43%;
  transform: translateX(-50%);
  top: -110px;
}
.view-home .l-serves .c-screen-img {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 53%;
}
@media (max-width: 767.98px) {
  .view-home .l-serves .c-screen-img {
    position: relative;
    transform: none;
    width: 90%;
  }
  .view-home .l-serves .c-float-shape {
    top: 50vw;
    left: auto;
    right: -50px;
    transform: none;
  }
}
.view-home .c-serve-item {
  text-align: center;
  transition: all 0.3s;
  min-width: 100px;
  margin-bottom: 3rem;
  flex: 0 0 33.3333333333%;
}
@media (max-width: 575.98px) {
  .view-home .c-serve-item {
    flex: 0 0 50%;
  }
}
.view-home .c-serve-item svg > g path {
  transition: all 0.3s;
}
.view-home .c-serve-item:hover {
  color: #ff6666 !important;
  text-decoration: none;
}
.view-home .c-serve-item:hover svg > g path {
  stroke: #ff6666;
}
.view-home .l-core {
  margin-top: 200px;
  margin-bottom: 100px;
}
.view-home .l-core .c-cat-img {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(5%, -50%);
  width: 65%;
}
.view-home .l-core__text {
  line-height: 2.3;
  position: relative;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .view-home .l-core {
    margin-top: 0;
  }
  .view-home .l-core .c-cat-img {
    position: relative;
    top: 0;
    left: -40%;
    transform: none;
    width: 200%;
    max-width: 1200px;
  }
}
.view-opensource-list {
  --body-round: 150px;
}
.view-opensource-list .c-banner--opensource {
  padding-bottom: 50px;
}
@media (min-width: 992px) {
  .view-opensource-list .c-banner--opensource {
    border-bottom-left-radius: var(--body-round);
    border-bottom-right-radius: var(--body-round);
  }
}
.view-opensource-list .l-opensource-page {
  position: relative;
  z-index: 1;
  padding-top: 64px;
}
.view-opensource-list .l-os-section {
  padding-top: 50px;
  padding-bottom: 50px;
}
.view-opensource-list .c-os-item {
  transition: all 0.3s;
  opacity: 0.9;
}
.view-opensource-list .c-os-item:hover {
  opacity: 1;
}
.view-opensource-list .c-os-item__cover img {
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.layout-services-serve .c-serve-item {
  text-align: center;
  transition: all 0.3s;
}
.layout-services-serve .c-serve-item svg {
  width: 117px;
  height: 117px;
}
.layout-services-serve .c-serve-item svg > g path {
  transition: all 0.3s;
}
.layout-services-serve .c-serve-item:hover, .layout-services-serve .c-serve-item.active {
  color: #ff6666 !important;
}
.layout-services-serve .c-serve-item:hover svg > g path, .layout-services-serve .c-serve-item.active svg > g path {
  stroke: #ff6666 !important;
}
@media (max-width: 575.98px) {
  .layout-services-serve .c-serve-item {
    width: 25%;
    font-size: 0.75rem;
  }
  .layout-services-serve .c-serve-item svg {
    width: 64px;
    height: 64px;
  }
}
.layout-services-serve .c-service-slider-item {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.layout-services-serve .c-service-slider-item__content {
  position: relative;
  z-index: 1;
}
.layout-services-serve .c-service-slider-item__body {
  height: 400px;
}
.layout-services-serve .c-service-slider-item .h-bg-overlay {
  background-color: #ff6666;
  opacity: 0.8;
}
.layout-services-serve .l-working-flow {
  position: relative;
  display: flex;
  height: 2300px;
  height: 2200px;
}
@media (max-width: 767.98px) {
  .layout-services-serve .l-working-flow {
    height: 2500px;
  }
}
.layout-services-serve .c-timeline {
  position: relative;
  height: 1800px;
}
.layout-services-serve .c-timeline .c-timeline-step {
  display: flex;
  justify-content: space-between;
  width: 380px;
  right: 280px;
  position: absolute;
}
.layout-services-serve .c-timeline .c-timeline-step__card {
  position: sticky;
  top: 100px;
}
.layout-services-serve .c-timeline .c-timeline-step--right {
  width: 320px;
  right: 280px;
}
.layout-services-serve .c-timeline .c-timeline-step--left {
  width: 320px;
  left: 280px;
}
.layout-services-serve .c-timeline .c-timeline-step--left .c-timeline-step__container {
  order: 1;
}
.layout-services-serve .c-timeline .c-timeline-step--left .c-timeline-step__line {
  order: 2;
}
@media (max-width: 1399.98px) {
  .layout-services-serve .c-timeline .c-timeline-step--center {
    right: 190px;
  }
  .layout-services-serve .c-timeline .c-timeline-step--right {
    right: 190px;
  }
  .layout-services-serve .c-timeline .c-timeline-step--left {
    left: 190px;
  }
}
@media (max-width: 1199.98px) {
  .layout-services-serve .c-timeline .c-timeline-step--center {
    right: 100px;
  }
  .layout-services-serve .c-timeline .c-timeline-step--right {
    right: 100px;
  }
  .layout-services-serve .c-timeline .c-timeline-step--left {
    left: 100px;
  }
}
@media (max-width: 991.98px) {
  .layout-services-serve .c-timeline .c-timeline-step--center {
    right: -20px;
  }
  .layout-services-serve .c-timeline .c-timeline-step--right {
    right: -20px;
  }
  .layout-services-serve .c-timeline .c-timeline-step--left {
    left: -20px;
  }
}
@media (max-width: 767.98px) {
  .layout-services-serve .c-timeline .c-timeline-step {
    position: static;
    justify-content: center;
    width: 100%;
    margin-bottom: 2rem;
  }
  .layout-services-serve .c-timeline .c-timeline-step__line {
    display: none;
  }
}
.layout-services-serve .c-timeline .c-timeline-step--01 .c-timeline-step__line {
  height: 1153px;
}
.layout-services-serve .c-timeline .c-timeline-step--02 {
  top: 305px;
}
.layout-services-serve .c-timeline .c-timeline-step--02 .c-timeline-step__line {
  height: 426px;
}
.layout-services-serve .c-timeline .c-timeline-step--03 {
  top: 610px;
}
.layout-services-serve .c-timeline .c-timeline-step--03 .c-timeline-step__card {
  top: 150px;
}
.layout-services-serve .c-timeline .c-timeline-step--03 .c-timeline-step__line {
  height: 543px;
}
.layout-services-serve .c-timeline .c-timeline-step--03 .c-timeline-step__line .c-line__mark {
  top: 120px;
}
.layout-services-serve .c-timeline .c-timeline-step--04 {
  top: 915px;
}
.layout-services-serve .c-timeline .c-timeline-step--04 .c-timeline-step__line {
  height: 757px;
}
.layout-services-serve .c-timeline .c-timeline-step--04 .c-line, .layout-services-serve .c-timeline .c-timeline-step--04 .card-header {
  background-color: #4d4d4d !important;
}
.layout-services-serve .c-timeline .c-timeline-step--05 {
  top: 1220px;
}
.layout-services-serve .c-timeline .c-timeline-step--05 .c-timeline-step__line {
  height: 697px;
}
.layout-services-serve .c-timeline .c-timeline-step--06 {
  top: 1825px;
}
.layout-services-serve .c-timeline .c-timeline-step--06 .c-timeline-step__line {
  height: 338px;
}
.layout-services-serve .c-timeline .c-timeline-fragment {
  position: absolute;
  display: none;
}
.layout-services-serve .c-timeline .c-timeline-fragment__line {
  height: 100%;
}
.layout-services-serve .c-timeline .c-timeline-fragment--1 {
  right: 480px;
  top: 455px;
  height: 70px;
}
.layout-services-serve .c-timeline .c-timeline-fragment--2 {
  right: 480px;
  top: 1463px;
  height: 84px;
}
.layout-services-serve .c-timeline .c-timeline-fragment--3 {
  right: 540px;
  top: 1996px;
  height: 45px;
}
@media (max-width: 991.98px) {
  .layout-services-serve .c-timeline .c-timeline-fragment--1 {
    right: 390px;
  }
  .layout-services-serve .c-timeline .c-timeline-fragment--2 {
    right: 390px;
  }
  .layout-services-serve .c-timeline .c-timeline-fragment--3 {
    right: 450px;
  }
}
@media (max-width: 767.98px) {
  .layout-services-serve .c-timeline .c-timeline-fragment--1 {
    right: 270px;
  }
  .layout-services-serve .c-timeline .c-timeline-fragment--2 {
    right: 270px;
  }
  .layout-services-serve .c-timeline .c-timeline-fragment--3 {
    right: 330px;
  }
}
@media (max-width: 575.98px) {
  .layout-services-serve .c-timeline .c-timeline-fragment--1 {
    right: 200px;
  }
  .layout-services-serve .c-timeline .c-timeline-fragment--2 {
    right: 200px;
  }
  .layout-services-serve .c-timeline .c-timeline-fragment--3 {
    right: 240px;
  }
}
.layout-services-serve .c-timeline .c-step-card {
  width: 255px;
}
.layout-services-serve .c-timeline .c-step-card__header {
  position: relative;
  font-size: 1.125rem;
  font-weight: 600;
}
.layout-services-serve .c-timeline .c-step-card__title {
  margin-bottom: -5px;
}
.layout-services-serve .c-timeline .c-step-card__no {
  position: absolute;
  top: -1rem;
  right: 2rem;
  font-size: 4.75rem;
  font-weight: 100;
}
.layout-services-serve .c-timeline .c-step-card__list-item {
  line-height: 2.3;
}
.layout-services-serve .c-line {
  position: relative;
  width: 30px;
  border-radius: 50px;
  padding-top: 8px;
  padding-bottom: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}
.layout-services-serve .c-line__mark {
  position: sticky;
  margin-left: 7px;
  top: 100px;
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  z-index: 1;
}
.layout-services-serve .c-line__step {
  position: absolute;
  margin-left: 7px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.layout-services-serve .c-line__end, .layout-services-serve .c-line__start {
  position: absolute;
  margin-left: 7px;
  width: 16px;
  height: 16px;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 50%;
}
.layout-services-serve .c-line__end {
  bottom: 8px;
}

.view-portfolio-item .l-portfolio-item img {
  max-width: 100%;
  height: auto !important;
}
.view-portfolio-item .l-portfolio-item .h-img-loading {
  overflow: hidden;
}
.view-portfolio-item .l-portfolio-item .h-img-loading img {
  filter: blur(40px);
}