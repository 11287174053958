.l-promo-sections {
  &--as-footer .l-section--advantage {
    padding-bottom: 500px !important;
    margin-bottom: -800px;
  }

  .l-section {
    .c-red-btn {
      font-size: 20px;
      min-width: 350px;
      margin-top: 32px;
      background-color: $primary;
      color: white;
      padding: 9px 0;

      &:hover {
        background-color: $link-hover-color;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
        color: white;
      }

      @media (max-width: 576px) {
        width: 100%;
        min-width: unset;
      }
    }

    .c-outline-red-btn {
      font-size: 20px;
      min-width: 350px;
      margin-top: 32px;
      border: 1px solid white;
      background-color: $primary;
      color: white;
      padding: 9px 0;

      &:hover {
        background-color: white;
        color: $primary;
      }

      @media (max-width: 992px) {
        margin-top: 1rem;
      }

      @media (max-width: 576px) {
        width: 100%;
        min-width: unset;
      }
    }

    .c-white-btn {
      font-size: 20px;
      min-width: 350px;
      margin-top: 32px;
      background-color: white;
      color: $primary;
      padding: 9px 0;

      &:hover {
        color: $primary !important;
        background-color: #F2F2F2 !important;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
      }

      @media (max-width: 576px) {
        width: 100%;
        min-width: unset;
      }

      @media (max-width: 1200px) and (min-width: 992px) {
        width: 100%;
        min-width: unset;
      }
    }

    .l-title-wrap {
      position: relative;
      z-index: 3;
      text-align: center;

      @media (max-width: 992px) {
        text-align: left;
      }
    }

    .c-title {
      font-size: 42px;
      font-weight: 500;
      color: $gray-900;

      @media (max-width: 576px) {
        font-size: 2rem;
      }
    }

    .c-subtitle {
      line-height: 38px;
    }

    .service-card-mb {
      margin-bottom: 72px;

      @media (max-width: 992px) {
        margin-bottom: 40px;
      }
    }

    .c-info-card {

      &__body {
        padding: 0 12px;
      }

      &__title {
        margin-top: 1rem;
        font-size: 24px;
        line-height: 38px;
        font-weight: 500;
        color: $gray-800;
      }

      &__subtitle {
        margin-top: 12px;
        font-size: 14px;
        line-height: 24px;
        color: $gray-500;
      }
    }

    // Cases
    &--cases {
      padding-top: 96px;
      padding-bottom: 120px;
      background-color: $gray-100;
      position: relative;
      z-index: 9;

      @media (max-width: 992px) {
        padding-top: 48px;
        padding-bottom: 48px;
        z-index: unset;
      }

      .bg-industry3 {
        max-width: 57px;
        position: absolute;
        top: 1422px;
        right: 35%;
        z-index: 1;

        @media (max-width: 1440px) {
          top: 1704px;
        }

        @media (max-width: 992px) {
          display: none;
        }
      }

      .portfolio-swiper {
        width: 100%;
        height: auto;

        .swiper-slide {
          width: 100%;
          height: auto;
          border-radius: 1rem;

          @media (max-width: 576px) {
            border-radius: 2rem;
          }

          img {
            width: 100%;
            height: auto;
            border-radius: 2rem;
          }
        }
      }

      .content-body {
        margin-top: 2rem;
        color: $gray-800;
        padding: 0 32px;
        line-height: 30px;

        @media (max-width: 576px) {
          margin-top: 1rem;
          padding: 0 16px;
        }

        .slide-title {
          font-size: 28px;
          font-weight: 500;
          line-height: 38px;
        }
      }

      .swiper-pagination--portfolio {
        text-align: center;
        margin-top: 38px;
      }

      .swiper-pagination-bullet {
        background-color: #D8D8D8;
        width: 20px;
        height: 5px;
        border-radius: 20px;
        opacity: 1;
      }

      .swiper-pagination-bullet-active {
        width: 50px;
        background-color: $primary;
      }

      .swiper-button-prev {
        left: 60px;
        margin-top: 0;
        padding: 3rem 10px;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 32px;
        transform: translateY(-50%);
        opacity: 0;
        transition: .15s ease-in-out;
      }

      .swiper-button-prev::after {
        font: var(--fa-font-solid);
        content: "\f060";
        color: white;
        font-size: 1.5rem;
      }

      .swiper-button-next {
        right: 60px;
        margin-top: 0;
        padding: 3rem 10px;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 32px;
        transform: translateY(-50%);
        opacity: 0;
        transition: .15s ease-in-out;
      }

      .swiper-button-next::after {
        font: var(--fa-font-solid);
        content: "\f061";
        color: white;
        font-size: 1.5rem;
      }

      .swiper-nav-show {
        opacity: 1;
      }
    }

    // Articles
    &--articles {
      padding: 96px 0;
      background-color: #6E96DB;
      position: relative;
      overflow: hidden;

      @media (max-width: 992px) {
        overflow: unset;
        padding: 48px 0;
      }

      .article-swiper {
        width: 100%;
        height: auto;

        .swiper-slide {
          width: 100%;
          height: auto;
          border-radius: 1rem;

          @media (max-width: 576px) {
            border-radius: 2rem;
          }
        }
      }

      .swiper-pagination--article {
        text-align: center;
        margin-top: 38px;
      }

      .swiper-pagination-bullet {
        background-color: #D8D8D8;
        width: 20px;
        height: 5px;
        border-radius: 20px;
        opacity: 1;
      }

      .swiper-pagination-bullet-active {
        width: 50px;
        background-color: $primary;
      }

      .swiper-button-prev {
        left: -50px;
        margin-top: 0;
        padding: 3rem 10px;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 32px;
        transform: translateY(-50%);
        transition: .15s ease-in-out;
      }

      .swiper-button-prev::after {
        font: var(--fa-font-solid);
        content: "\f060";
        color: white;
        font-size: 1.5rem;
      }

      .swiper-button-next {
        right: -50px;
        margin-top: 0;
        padding: 3rem 10px;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 32px;
        transform: translateY(-50%);
        transition: .15s ease-in-out;
      }

      .swiper-button-next::after {
        font: var(--fa-font-solid);
        content: "\f061";
        color: white;
        font-size: 1.5rem;
      }

      .swiper-nav-show {
        opacity: 1;
      }

      .c-article-card {
        position: relative;
        border-radius: 32px;
        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
        background-color: white;
        height: 100%;
        overflow: hidden;

        &__body {
          padding: 32px;
        }

        .card-title {
          font-size: 24px;
          font-weight: 500;
          line-height: 30px;
          color: $gray-900;
          margin-bottom: 8px;
        }

        .card-info {
          font-size: 14px;
        }
      }

      &__actions {
        font-size: 20px;
        color: white;
        padding: 9px 0;

        &:hover,
        &:focus {
          background-color: white;
          color: #6E96DB;
        }
      }


      .bg-article1 {
        max-width: 54px;
        position: absolute;
        top: 1700px;
        right: 25%;
        z-index: 9;

        @media (max-width: 1440px) {
          top: 1850px;
        }

        @media (max-width: 1280px) {
          top: 2035px;
        }

        @media (max-width: 992px) {
          top: 2035px;
          right: 15%;
        }

        @media (max-width: 576px) {
          top: 2536px;
          right: 5%;
        }
      }

      .bg-article2 {
        max-width: 56px;
        position: absolute;
        top: 2225px;
        left: 25%;
        z-index: 9;

        @media (max-width: 1440px) {
          top: 2500px;
        }

        @media (max-width: 1280px) {
          top: 2700px;
        }

        @media (max-width: 992px) {
          top: -600px;
          left: 15%;
        }

        @media (max-width: 576px) {
          top: -430px;
          left: 10%;
        }

        @media (max-width: 380px) {
          top: 3422px;
          left: 10%;
        }
      }
    }

    // Advantages
    &--advantage {
      position: relative;
      padding-top: 96px;
      padding-bottom: 120px;
      background-color: #ebebeb;

      @media (max-width: 992px) {
        padding-top: 48px;
        overflow: hidden;
      }

      @media (max-width: 768px) {
        padding-bottom: 80px;
      }

      @media (max-width: 576px) {
        padding-bottom: 50px;
      }

      .c-advantage-cards-row {
        margin-top: 64px;
        position: relative;
        z-index: 3;

        @media (max-width: 992px) {
          margin-top: 0;
        }
      }

      .c-advantage-card {
        background-color: white;
        position: relative;
        margin-left: 19px;
        padding: 22px 24px 46px 24px;
        border-radius: .5rem;
        box-shadow: 0px 36px 20px -16px rgba(0, 0, 0, 0.06);
        height: 100%;

        @media (max-width: 992px) {
          margin-left: 1rem;
          height: auto;
        }

        &:first-child {
          margin-left: 0;
        }

        &__header {
          display: flex;
          justify-content: center;
          align-items: center;

          .deco-hole {
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background-color: #EBEBEB;
            margin-right: 1.5rem;

            &:last-child {
              margin-right: 0;
            }
          }
        }

        &__body {
          margin-top: 37px;
        }

        .card-title {
          font-size: 24px;
          font-weight: 700;
          color: #4D4D4D;
          margin-bottom: 12px;
        }

        .card-info {
          color: #999999;
        }


        .bg-tape {
          position: absolute;
          width: 56px;
          top: -34px;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .bg-advantage {
        position: absolute;
        top: -72px;
        right: 0;
        z-index: 1;

        @media (max-width: 1400px) {
          width: 75%;
        }

        @media (max-width: 992px) {
          width: 120%;
          position: static;
          transform: translateX(10%);
        }

        @media (max-width: 768px) {
          width: 110%;
          position: static;
          transform: translateX(10%);
        }

        @media (max-width: 576px) {
          width: 130%;
          position: static;
          transform: translateX(-10%);
        }
      }

      .bg-advantage2 {
        max-width: 61.35px;
        position: absolute;
        top: 1920px;
        right: 5%;
        z-index: 0;

        @media (max-width: 1440px) {
          top: 2300px;
        }

        @media (max-width: 1280px) {
          top: 2380px;
        }

        @media (max-width: 992px) {
          display: none;
        }
      }
    }

    // End section
  }
}
