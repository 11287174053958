// Part of simular file.

.h-std-card {
  box-shadow: $box-shadow;
  border-radius: $border-radius-2xl;
  border: none;

  .card-header, .card-img-top {
    border-top-left-radius: $border-radius-2xl;
    border-top-right-radius: $border-radius-2xl;
  }

  .card-header {
    padding: 2rem;
  }

  .card-body {
    padding: 1.25rem 2rem;
  }
}
