
// Partners
.c-partner-item {
  .card-body {
    position: relative;
    padding: 100% 0 0;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 80% !important;
      width: 80% !important;
      object-fit: contain;
      filter: grayscale(100);
      transition: all .5s;
    }
  }

  &:hover {
    img {
      filter: grayscale(0);
    }
  }
}

.c-partners-slider-control {
  button:hover {
    color: $primary !important;
  }
}
