// Part of simular file.

.article-item {
  z-index: 1;

  .article-image {
    position: relative;

    div {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-size: cover;
      background-position: center center;

      @include media-breakpoint-down(md) {
        position: static;
        padding-top: 54%;
      }
    }

    //img {
    //  position: absolute;
    //  top: 0;
    //  left: 0;
    //  right: 0;
    //  bottom: 0;
    //}
  }
}

.c-article-item.h-std-card {
  img {
    transition: .3s all ease;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}
