
.c-banner {
  padding-top: 7rem;
  color: white;
  position: relative;
  isolation: isolate;

  &__content {
    position: relative;
    z-index: 1;
  }

  &__overlay {
    position: absolute;
  }

  &--placeholder {
    height: 9rem;
    width: 100%;
  }
}

.c-banner-title {
  position: relative;

  &__image {
    position: absolute;
    height: 560px;
    width: 1185px;

    background-size: contain;
    background: transparent no-repeat center center;
  }

  &__content {
    min-height: 400px;
    mix-blend-mode: color-dodge;
  }

  &--right {
    overflow-x: hidden;
    padding-bottom: 200px;
    margin-bottom: -200px;

    .c-banner-title__image {
      left: 39vw;
      top: -50px;
      //transform: scaleX(-1);
      background-image: url(../../images/layout/banner-shapes-right.svg);
    }
    .c-banner-title__content {
      //margin-left: auto;
    }

    @include media-breakpoint-down(md) {
      .c-banner-title__content {
        margin-top: 100px;

        h1 {
          font-size: 3.75rem;
        }
      }

      .c-banner-title__image {
        left: 10vw;
        top: -130px
      }
    }
  }

  &--left {
    .c-banner-title__image {
      right: 45vw;
      top: -70px;
      //transform: scaleX(-1);
      background-image: url(../../images/layout/banner-shapes-left.svg);
    }
    .c-banner-title__content {
      margin-left: auto;
    }

    @include media-breakpoint-down(md) {
      .c-banner-title__content {
        margin-top: 100px;

        h1 {
          font-size: 3.75rem;
        }
      }

      .c-banner-title__image {
        right: 10vw;
        top: -200px
      }
    }
  }
}

.l-small-banner {
 min-height: 350px;
  border-bottom-right-radius: 500px;
  padding-bottom: 40px;

  @include media-breakpoint-down(md) {
    border-bottom-right-radius: 0;
  }
}
